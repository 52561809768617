.radio {
  display: grid;
  grid-template-columns: 20px auto;
  align-items: start;
  grid-gap: 8px;
  cursor: pointer;
  user-select: none;
  text-align: left;

  & + .radio {
    margin-top: var(--page-padding);
  }

  &::before {
    content: '';
    display: inline-block;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    border: 2px solid #bdc0c1;
    box-shadow: inset 0 0 0 10px #fff;
    background: var(--color-primary-new);
    transition: all 0.2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
  }

  &:has(input:checked) {
    &::before {
      box-shadow: inset 0 0 0 3px #fff;
      border-color: var(--color-primary-new);
    }
  }

  input {
    display: none;
  }
}

// Quick fix for Tooltip-Box component
.mat-mdc-tooltip.Tooltip-Box {
  .mdc-tooltip__surface {
    padding: 0;
    min-height: auto;
    max-height: 100%;
    --mdc-plain-tooltip-supporting-text-font: sans-serif;
    --mdc-plain-tooltip-supporting-text-size: 14px;
    --mdc-plain-tooltip-supporting-text-line-height: 1.2;
    --mdc-plain-tooltip-supporting-text-weight: 400;
    --mdc-plain-tooltip-supporting-text-color: #000;
    --mdc-plain-tooltip-container-color: #fff;
  }
}

._hide {
  &_md {
    @include breakpoint-md {
      display: none;
    }
  }

  &_mdDown {
    @include breakpoint-md-down {
      display: none;
    }
  }
}

._is-starter-campaign {
  ._hide-on-starter-campaign {
    display: none !important;
  }
}

.is-hidden {
  display: none !important;
}

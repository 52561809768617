.MatSelect {
  padding: 0.6rem 1.125rem 0.4rem;
  color: #2a2a2a;
  background-color: #fff;
  border: 2px solid #dadfe6;
  border-radius: 0.375rem;
}

/* Previously the dropdown menu width could be wider than the parent form-field,
but now mdc select has changed the dropdown is the same width as the form-field
so we will need to override the width to auto */
.cdk-overlay-pane.oneSelect {
  width: auto !important;
}

@import 'proof-factor/import';

.mat-mdc-table,
.mat-table {
  font-family: 'Roboto', 'Helvetica Neue', sans-serif;
  width: 100%;

  .mat-mdc-header-cell,
  .mat-header-cell {
    color: #000;
  }
  tr.mat-mdc-header-row,
  tr.mat-header-row {
    height: 30px;
  }

  td.mat-cell:last-of-type,
  td.mat-footer-cell:last-of-type,
  th.mat-header-cell:last-of-type,

  td.mat-cell:first-of-type,
  td.mat-footer-cell:first-of-type,
  th.mat-header-cell:first-of-type,

  td.mat-mdc-cell:last-of-type,
  td.mat-mdc-footer-cell:last-of-type,
  th.mat-mdc-header-cell:last-of-type,

  td.mat-mdc-cell:first-of-type,
  td.mat-mdc-footer-cell:first-of-type,
  th.mat-mdc-header-cell:first-of-type,

  .mat-cell,
  .mat-mdc-cell,
  .mat-mdc-header-cell,
  .mat-header-cell {
    padding: 5px;
  }

  .Button {
    white-space: nowrap;
  }

}

.mat-paginator-container {
  font-family: 'Roboto', 'Helvetica Neue', sans-serif;
  min-height: 56px;
  font-size: 12px;
  color: rgb(0, 0, 0, 0.54);
}

// MOBILE TABLE LAYOUT
@include breakpoint-768 {
  .mat-mdc-table {

    .mat-row:hover {
      background: #fff;
    }
    .mat-mdc-row:hover {
      background: #fff;
    }

    thead {
      display: none !important;
    }

    &::ng-deep tbody tr {
      display: block;
      height: auto;
      padding-top: 15px;
    }

    td {
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 1fr;
      border-bottom: 0;
      min-height: auto;
      text-align: left !important;
      width: 100% !important;
      max-width: none !important;
      filter: none !important;

      &::before {
        content: attr(data-title);
        font-weight: bold;
        margin-right: 15px;
      }
    }

    .mat-column-actions {
      display: flex;
      justify-content: flex-end;
      border-bottom: 1px solid #eee;
      padding-bottom: 15px;
      min-width: 260px;

      &::before {
        display: none;
      }

      .pf-button {
        height: 32px;
      }

      // .mat-menu-trigger {
      //   position: absolute;
      //   top: 5px;
      //   right: 0;
      //   z-index: 1;
      // }

    }
  }
  td.mat-mdc-cell,
  td.mat-cell {
    min-width: unset!important;
    width: auto!important;
  }
}

// DESKTOP TABLE LAYOUT
.mdc-data-table__row:last-child .mat-mdc-cell {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.mdc-data-table__cell {
  overflow: unset !important;
}

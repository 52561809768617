@use 'sass:math';

$pi: 3.14159265359;
$_precision: 10;

@function pow($base, $exp) {
  $value: $base;
  @if $exp > 1 {
    @for $i from 2 through $exp {
      $value: $value * $base;
    }
  }
  @if $exp < 1 {
    @for $i from 0 through -$exp {
      $value: math.div($value, $base);
    }
  }
  @return $value;
}

@function fact($num) {
  $fact: 1;
  @if $num > 0 {
    @for $i from 1 through $num {
      $fact: $fact * $i;
    }
  }
  @return $fact;
}

@function _to_unitless_rad($angle) {
  @if unit($angle) == 'deg' {
    $angle: math.div($angle, 180deg) * $pi;
  }
  @if unit($angle) == 'rad' {
    $angle: math.div($angle, 1rad);
  }
  @return $angle;
}

@function sin($angle) {
  $a: _to_unitless_rad($angle);
  $sin: $a;
  @for $n from 1 through $_precision {
    $sin: $sin + math.div(pow(-1, $n), fact(2 * $n + 1))  * pow($a, (2 * $n + 1));
  }
  @return $sin;
}

@function cos($angle) {
  $a: _to_unitless_rad($angle);
  $cos: 1;
  @for $n from 1 through $_precision {
    $cos: $cos +  math.div(pow(-1, $n), fact(2 * $n))  * pow($a, 2 * $n);
  }
  @return $cos;
}

@function tan($angle) {
  @return math.div(sin($angle), cos($angle));
}

@function disc($number-of-circles, $size) {
  $result: 0 0 0 #fff;
  @for $n from 1 through $number-of-circles {
    $angle-in-radians: $n * math.div($pi * 2, $number-of-circles);
    $result: $result, ($size * cos($angle-in-radians)) ($size * sin($angle-in-radians)) 0 darken(#fff, 2% * $n);
  }
  @return $result;
}

.Circular-Loader {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  vertical-align: bottom;
  &::after {
    content: '';
    display: block;
    width: 4px;
    height: 4px;
    box-shadow: disc(12, 10px);
    border-radius: 50%;
    animation: CircularLoaderRotate 380ms infinite linear;
    animation-timing-function: steps(12, end);
  }
}

@keyframes CircularLoaderRotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

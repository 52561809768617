
.CouponConfigFields-CollapsibleSection {
  filter: drop-shadow(0 2px 8px rgba(15, 38, 52, 0.08));

  &:not(:first-child) {
    margin-top: 16px;
  }


  .SocialPopup-Subtitle {
    margin: 0;
  }

  .PluginConfig-Line {
    margin: 0;
  }
}

.CouponConfigFields-CollapsibleSectionTrigger {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  font-weight: 600;
  //padding-left: 10px;


  ion-icon {
    transition: transform 0.3s;
  }


}


.CouponConfigFields-HeaderTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
  border-left: 4px solid #009dff;
  filter: drop-shadow(0 2px 8px rgba(0, 0, 0, 0.08));

  &._collapsed {
    border-left: 4px solid transparent;

    &._collapsed ion-icon[name='chevron-down'] {
      transform: rotate(-90deg);
    }

  }

}

.SocialPopup-Subtitle {
  background: #fff;
  color: #009dff;
  padding: 8px 10px;
  & ion-icon[name='chevron-down'] {
    color: #009dff;
    margin: auto 10px;
    font-size: 20px;
  }

  &._collapsed {
    color: #333333;
    ion-icon[name='chevron-down'] {
      color: #333333;
    }
  }

  &._error {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 20px;
    background: #ffffff;
    cursor: pointer;
    user-select: none;
    ion-icon {
      transition: transform 0.3s;
    }

    &._collapsed ion-icon {
      transform: rotate(-180deg);
    }
  }
}

.SocialPopup-Configs {
  background-color: #fff;
  padding: 0 20px;
}

.SocialPopup-Control {
  padding: 20px 0;
  &:not(:first-child) {
    padding-top: 0;
  }

  &-Line {
    position: relative;
    margin-left: 15px;
  }

  &._split_width {
    .SocialPopup-Control-Label,
    .SocialPopup-Control-Input {
      flex: 1 0 50%;
    }
  }

  &._one-line {
    display: flex;
    align-items: center;

    mat-select {
      padding: 7px 14px;
    }

    .SocialPopup-Control-Label {
      color: #333;
      font-size: 16px;
    }

    label {
      margin-bottom: 0;
      color: #333;
      font-size: 14px;
    }

    .SocialPopup-Control-Input {
      width: 115px;
      height: 40px;
      &._lg {
        width: 153px;
      }
      &-Label {
        position: absolute;
        top: 9px;
        right: 9px;
      }
    }
  }


  .mat-checkbox-layout {
    white-space: normal;

    .mat-checkbox-label {
      color: #333;
    }
  }

}

.SocialPopup-Control-Label {
  font-size: 16px;
  color: #828587;

}
.SocialPopup-Control-Input {
  display: block;
  height: 40px;
  width: 100%;
  border: 1px solid #bdc0c1;
  border-radius: 4px;
  padding: 8px 16px;
  line-height: 1.5;
  color: #333;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    appearance: none;
  }
  &._is-invalid {
    border-color: #ff4c6a;
  }
}

.SocialPopup-Aside {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 5px;
  margin: 0 -10px;

  @include breakpoint-1024 {
    height: unset;
  }

  @include custom-scrollbar;

}

.SocialPopup-Section {

}

.SocialPopup-More {
  margin-left: auto;
  margin-top: 5px;
  a {
    color: #009dff;
    font-size: 16px;
    font-weight: 600;
    &:hover {
      text-decoration: none;

    }
  }
}

.SocialPopup-Refresh-Button {
  color: #009dff;
  background: transparent;
  border: none;
  font-weight: 600;
  margin-left: 30px;
  padding: 0;
  ion-icon {
    margin-right: 5px;
    display: inline-block;
  }
  &:focus ion-icon {
    animation: rotate 1s ease-in-out 0s;
  }

  @keyframes rotate {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }

}



.SocialPopup-Variables {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  line-height: 16px;
  &-Toggle {
    border: 1px solid #d9dcde;
    display: flex;
    padding: 5px;
    border-radius: 4px;
  }
}


.SocialPopup-Toggle-Button {
  font-size: 14px;
  padding: 6px 10px;
  background: none;
  border: none;
  color: #828587;

  &._active {
    border-radius: 7px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.06), 0 3px 8px rgba(0, 0, 0, 0.15);
    background: #009dff;
    color: #fff;
  }

  &:last-child {
    margin-left: 5px;
  }

}

.SocialPopup-Examples {
  display: flex;
  flex-direction: column;
  &__Title {
    margin-bottom: 10px;
    font-weight: 600;
    display: flex;

    .Tooltip {
      margin-left: 5px;
    }

    .pf-button {
      margin-left: 10px;
      margin-right: auto;
      margin-top: -7px;
      white-space: nowrap;
      @media screen and (min-width: $xl) {
        margin-left: auto;
        margin-right: 0;
      }
      mat-spinner {
        display: inline-block;
        vertical-align: bottom;
        margin-left: 6px;
        ::ng-deep circle {
          stroke: #fff;
        }
      }
      &:hover {
        mat-spinner {
          ::ng-deep circle {
            stroke: $color-primary-new;
          }
        }
      }
    }
  }
}

.Product {
  &-Item {
    display: flex;
    align-items: center;
    padding: 8px 0;
    &:not(:last-child) {
      border-bottom: 1px solid #dfe3ea;
    }
  }
  &-Image {
    height: 60px;
    width: 60px;
    border-radius: 3px;
    border: 1px solid #e8ebf0;
    margin: 0 15px;
    img {
      width: 58px;
      height: 58px;
      object-fit: contain;
    }
  }
}
.Product-Item {
  display: flex;
}

.Radio-Item {
  //flex: 0 1 100%;
  display: flex;
  align-items: baseline;
  position: relative;
  user-select: none;


  input[type=radio] {
    position: absolute;
    opacity: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    border: 2px solid #bdc0c1;
    border-radius: 50%;
    cursor: pointer;

    &-sm {
      top: 50%;
      -webkit-transform: translateY(-50%) scale(0.75);
      -moz-transform: translateY(-50%) scale(0.75);
      -ms-transform: translateY(-50%) scale(0.75);
      transform: translateY(-50%) scale(0.75);
    }

    &::after {
      content: '';
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: $color-primary;
    }
  }

  & input:checked ~ label .checkmark {
    border: 2px solid $color-primary;
  }
  & input:checked ~ label .checkmark::after {
    display: block;
  }

  label {
    cursor: pointer;
    display: flex;
    // flex-flow: column nowrap;
    flex-flow: row nowrap;
    width: 100%;
    height: 100%;
    font-size: 16px;
    padding: 0 0 0 35px;
    color: #333333;
    background: #fff;
    transition: all 180ms;

  }

  small {
    margin-top: 5px;
  }

  small {
    display: block;
  }
}

.NoProducts {
  display: flex;
  align-items: center;
  &-Image {
    width: 64px;
  }

  &-Text {
    font-size: 16px;
    color: #828587;
    margin-left: 14px;
  }
}

.message {
  display: flex;
  background: #fff;
  margin-bottom: 10px;
  box-shadow: 0 2px 12px 0 #dfe3eb;

  &-icon-container {
    display: flex;
    align-items: center;
    position: relative;
    padding: 16px 8px;
  }

  &-icon {
    min-width: 24px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    margin-right: 15px;
  }

  &-box {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding: 5px 5px 5px 20px;
  }

  &-text {
  }

  &.warning {
    border-left: 4px solid #fdbf0a;

    .message-icon-container {
      background: #fff2d8;
    }

  }

  &.info {
    border-left: 4px solid #009dff;

    .message-icon-container {
      background: #eaf7ff;
    }

  }
  a {
    color: #009dff;
    text-decoration: underline;
  }
}

.Capture-InstructionList {
  counter-reset: instruction-counter;
  padding-left: 0;
  list-style: none;
  margin-top: 16px;
  margin-bottom: 0;
  li {
    counter-increment: instruction-counter;
    position: relative;
    display: flex;
    align-items: baseline;
    min-height: 43px;
    margin: 0 0 16px;
    &::before {
      content: counter(instruction-counter);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: #333;
      font-size: 16px;
      line-height: 24px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      color: white;
      text-align: center;
      margin-right: 16px;
    }
    a {
      color: #009dff;
      text-decoration: underline;
    }
    img {
      max-width: 460px;
      width: 100%;
      margin-top: 16px;
    }
  }
}

.pf-snackbar {
  background: #fff;
  box-shadow: 0 2px 12px 0 #b5b6b7;
  border-left: 4px solid #45cd8b;
  padding: 0 !important;

  .snackbar {
    &-body {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      height: 100%;
    }

    &-icon-container {
      display: flex;
      align-self: stretch;
      align-items: center;
      position: relative;
      padding: 16px 8px;
      background-color: #e7f8f1;

      img {
        width: 32px;
        height: 32px;
      }
    }

    &-text {
      color: #333;
      padding: 10px 16px;

      h4 {
        font-size: 16px;
        font-weight: 500;
        margin: 6px 0;

        &:empty {
          display: none;
        }
      }

      p {
        font-size: 15px;
        margin: 6px 0;

        &:empty {
          display: none;
        }
      }
    }

    &-close {
      font-size: 24px;
      line-height: 34px;
      width: 32px;
      height: 32px;
      text-align: center;
      margin: 0 0 auto auto;
      cursor: pointer;
      transform: rotate(0);
      transition: transform 180ms;
      &:hover {
        transform: rotate(90deg);
      }
      ion-icon {
        color: #828587;
      }
    }

    &-progress {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 4px;
      width: 100%;
      background: rgba(0, 0, 0, 0.075);

      &-bar {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.1);
        transition: width 300ms linear;
      }

      &.hidden {
        display: none;
      }
    }
  }

  &.success {
    border-left: 4px solid #45cd8b;

    .snackbar-icon-container {
      background: #e7f8f1;
    }

  }

  &.error {
    border-left: 4px solid #f8646f;

    .snackbar-icon-container {
      background: #ffe8ea;
    }

  }

  &.warning {
    border-left: 4px solid #fdbf0a;

    .snackbar-icon-container {
      background: #fff2d8;
    }

  }

  &.info {
    border-left: 4px solid #009dff;

    .snackbar-icon-container {
      background: #eaf7ff;
    }

  }
}

// deprecated, use pf-snackbar instead
.Blue-Snackbar {
  background: #7cb677;

  .mat-simple-snackbar-action {
    border-radius: 6px;
    color: #7cb677;
    background: #fff;
  }
  &.mat-snack-bar-container {
    color: #fff;
  }

  &._ForCampaigns {
    max-width: 200px !important;
    min-width: 200px !important;
    min-height: 64px !important;
    margin: 20px !important;
  }
  &._Sticked {
    margin-bottom: 0 !important;
  }
}

.Green-Snackbar {
  background: rgba(#7cb677, 0.9);
  margin-top: 6px !important;
  margin-right: 6px !important;
  min-height: 70px !important;
  min-width: 300px !important;

  .mat-simple-snackbar-action {
    border-radius: 6px;
    color: rgba(80, 215, 63, 1);
    background: #fff;
  }
  &.mat-snack-bar-container {
    padding: 6px 6px 12px;
    color: #fff;
  }

  &._ForCampaigns {
    max-width: 200px !important;
    min-width: 200px !important;
    min-height: 64px !important;
    margin: 20px !important;
  }
  &._Sticked {
    margin-bottom: 0 !important;
  }
}

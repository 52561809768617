@mixin input-track {
  $shadow-color: rgba(darken($separator, 30%), 0.2);
  width: 100%;
  height: 10px;
  cursor: pointer;
  //box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: #ebeef2;
  border-radius: 5px;
  border: 1px solid $shadow-color;
}

@mixin input-thumb {
  $shadow-color: rgba(darken($separator, 30%), 0.3);
  border: 1px solid $shadow-color;
  height: 16px;
  width: 16px;
  background: #ffffff;
  cursor: pointer;
  transition: 0.5s;
  border-radius: 50%;
  -webkit-appearance: none;
  margin: -3px 0 0;
  //box-shadow: 2px 2px 0 0 $shadow-color;
}


.Range {
  display: flex;
  justify-content: space-between;
  align-items: center;
  appearance: none;

  &-Input {
    //background: #f00;
    appearance: none;
    margin-left: 10px;
    width: 110px;


    &:focus {
      outline: none;

      &::-webkit-slider-thumb {
        outline: none;
      }
    }


    &::-webkit-slider-thumb {
      @include input-thumb;
    }

    &::-moz-range-thumb {
      @include input-thumb;
    }

    &::-webkit-slider-runnable-track {
      @include input-track;
    }

    &::-moz-range-track {
      @include input-track;
    }
  }

  &-Value {
    flex: 100%;
    text-align: right;
    font-size: 12px;
    color: #a2b0c3;
  }
}



$logo-text-color: #bdc0c1;
$logo-text-highlighted-color: #3f80ec;

.Logo {
  margin-right: 20px;
  cursor: default;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  font-size: 36px;
  line-height: $header-height;
  font-weight: 500;
  text-transform: lowercase;

  &-Text {
    color: $logo-text-color;

    &._highlighted {
      color: $logo-text-highlighted-color;
    }

    display: none;
    //@include breakpoint-sm-alt {
    //    //  display: inline;
    //    //}
  }

  &-Image {
    display: block;
    height: $header-height - 10px * 2;
    margin-top: 10px;
    //@include breakpoint-sm-alt {
    //  display: none;
    //}

    &._one {
      height: 60px;
      margin-top: 0;
    }
  }
}

$card-radius: 10px;

.dbcard {
  overflow: hidden;
  justify-self: stretch;
  padding: 20px;
  border-radius: $card-radius;
  background: #ffffff;
  box-shadow: 0 5px 10px rgba(68, 71, 83, 0.2);

  h2 {
    font-size: 28px;
    font-weight: normal;
  }

  & > * {
    display: block;
  }
}

.db-trend {
  height: 31px;
  &.is-up {
    color: $color-success;
  }
  &.is-down {
    color: $color-danger;
  }
}

.dbprogress {
  height: 8px;
  background: #f6f6f7;
  box-shadow: inset 0 0 0 1px $color-success;
  border-radius: 4px;
  overflow: hidden;
  &-val {
    height: 100%;
    border-radius: inherit;
    background: $color-success;
  }

  &.is-larger {
    .dbprogress-val {
      background: $color-danger;
    }
  }
}

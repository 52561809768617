.mat-mdc-form-field.mat-focused .mat-mdc-form-field-label {
  color: #a5abb3 !important;
}
.mat-mdc-chip {
  background-color: #0095f7 !important;
  --mdc-chip-label-text-color: #fff !important;
  ion-icon[name='close'] {
    color: #fff !important;
    text-align: center;
    // margin-bottom: 7px;
  }
}
.one-campaigns-filter {
  width: 100%;
  --mdc-theme-primary: #0095f7;
  --mdc-typography-body1-font-size: 14px;
  .mat-mdc-text-field-wrapper {
    padding: 0;
  }
  .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: rgba(0, 0, 0, 0.6) !important;
  }
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent;
  }
  &:hover .mat-mdc-form-field-focus-overlay {
    opacity: 0;
  }
  .mat-mdc-form-field-focus-overlay {
    opacity: 0 !important;
  }
  .mat-mdc-form-field-infix {
    min-height: auto;
  }
  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    padding-bottom: 0;
  }
}

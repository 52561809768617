.DesignControl {
  position: relative;
  padding-left: 40px;
  width: 100%;
  border: 2px solid #ebeef2;
  border-radius: 5px;

  &-Icon {
    position: absolute;
    width: 40px;
    height: 40px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    // border-right: 2px solid #ebeef2;
    padding: 2px;
    top: 0;
    left: 0;
    font-weight: bold;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    text-align: center;
    box-shadow: inset 0 0 0 0 rgba(#00a1fe, 0);
    background: rgba(#00a1fe, 0);
    transition: all 180ms;
    pointer-events: none;
    .DesignControl._hover & {
      cursor: pointer;
      pointer-events: all;
    }
    .DesignControl._hover:hover & {
      box-shadow: inset 0 0 2px 2px rgba(#00a1fe, 1);
      background: rgba(#00a1fe, 0.2);
    }
  }

  &-IconSymbol {
    display: block;
    color: #333;
    font-size: 24px;
    line-height: 24px;
    flex: 0 0 24px;
    &._line-through {
      text-decoration: line-through;
    }
    small {
      font-size: 14px;
      font-weight: bold;
      line-height: 14px;
    }
  }

  &-IconSwatch {
    display: block;
    width: 100%;
    flex: 0 0 8px;
    border-radius: 6px;
    margin: 3px 0 1px;
    border: 1px solid #e6e6e6;
  }

  &-Input {
    display: flex;
    flex-flow: row nowrap;
    height: 40px;
    & mat-slider.mdc-slider--discrete {
      min-width: unset;
    }
    input {
      height: 40px;
      border: 0;
      width: 60%;
      font-size: 15px;
      text-align: center;
      padding: 2px 8px;
      outline: none !important;
      background: none;
      -webkit-appearance: none;

      @include breakpoint-1280 {
        padding-left: 10px;
      }
      @include between-992-1280 {
        font-size: 14px;
      }
      &._alpha {
        width: calc(100% - 60px);
      }
      &._beta {
        width: 60px;
        padding: 2px 8px;
        text-align: right;
        border-left: 2px solid #ebeef2;
      }
    }

    &Select {
      width: 100%;
    }

    mat-select {
      padding: 10px !important;
    }

  }

}

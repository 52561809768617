@import 'proof-factor/import';

.SMSOnboarding-Layout {
  // General layout
  display: grid;
  width: 100%;
  position: relative;
  height: 100%;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr auto;
  gap: 10px;
  grid-template-areas: 'aside content'
                       'none footer';
  padding-left: 100px;
  transition: 0.3s ease-in-out;

  @include breakpoint-600 {
    padding-left: 0;
    grid-template-rows: 190px 1fr 70px;
    grid-template-columns: 1fr;
    grid-template-areas: 'aside'
                         'content'
                         'footer';
  }


  &.sidebar-pinned {
    padding-left: 200px;
    @include breakpoint-600 {
      padding-left: 0;
    }
  }

  &-Aside {
    position: absolute;
    height: 100%;
    @include breakpoint-600 {
      position: relative;
    }
  }

  //&-Aside {
  //  grid-area: aside;
  //
  //  &-Title {
  //    padding-bottom: 20px;
  //    margin-bottom: 20px;
  //    border-bottom: 1px solid #ebeef2;
  //  }
  //}

  &-Content {
    position: relative;
    grid-area: content;
    place-self: start;
    overflow-y: auto;
    width: 100%;
    //min-width: 500px;
    min-height: 50vh;
    max-height: 100%;
    @include custom-scrollbar();
    z-index: 1;

    &::after {
      content: '';
      background: url('/app_assets/sms/onboarding/message-conversation.svg');
      background-size: cover;
      filter: grayscale(1);
      opacity: 0.025;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      z-index: -1;
    }


    // Hide background here
    &.organization::after,
    &.keyword::after,
    &.try-it-out::after,
    &.setup-contact::after,
    &.collect-numbers::after,
    &.terms-of-service::after,
    &.sms-automations::after,
    &.welcome::after,
    &.tcpa::after,
    &.organization::after {
      display: none;
    }

    &.organization,
    &.keyword,
    &.try-it-out,
    &.setup-contact,
    &.tcpa,
    &.terms-of-service,
    &.collect-numbers,
    &.sms-automations {
      background: transparent !important;
      border: none;
      box-shadow: none;
      padding: 0;
      width: 100%;
      margin: 0 auto;

      .SMSOnboarding-Layout-Step {
        &-Head {
          border-radius: 5px;
          border: 1px solid #ebeef2;
          background: #fff;
          box-shadow: 0 2px 12px 0 #dfe3eb;
          display: grid;
          grid-template-rows: 65px auto;
          padding: 0;

          h2 {
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid #ebeef2;
            margin: 0;
            padding: 20px;
          }

          p {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
            padding: 15px;
          }
        }
      }
    }

    &.welcome {
      width: 100%;
      margin: 0 auto;
    }

    &.keyword,
    &.try-it-out {
      .SMSOnboarding-Layout-Step-Content {
        display: flex;
        @include breakpoint-730 {
          flex-direction: column;
        }
      }
      .SMSOnboarding-ConfigCards {
        margin-right: 24px;
        flex-grow: 1;
        @include breakpoint-730 {
         margin-right: 0;
        }
      }
    }
  }

  &-Footer {
    position: relative;
    grid-area: footer;
    width: 100%;
    z-index: 2;
    align-self: end;


    .Card {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;
      margin: auto;
      padding: 10px 20px;
    }
  }

  // Step layout
  &-Step {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas: 'head'
                         'content'
                         'preview';
    gap: 15px;

    &-Head {
      grid-area: head;
      border-bottom: 1px solid #ebeef2;
      text-align: center;

      h2 {
        color: $color-primary;
      }
    }

    &-Content {
      width: 100%;
      // max-width: 500px;
      place-self: center;
      justify-items: center;
    }

    &-Preview {
      // grid-area: preview;
      place-self: stretch;

      @include breakpoint-730 {
        margin: 0 auto;
      }
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

// Specific Step layout
.SMSOnboarding {
  // Organization
  &-Org {
    &-Title {
      font-size: 24px;
      font-weight: normal;
      margin-bottom: 15px;
    }

    &-Control-Group {
      display: flex;
      flex-flow: row nowrap;
      & > * + * {
        margin-left: 15px;
      }
    }

    &-InfoForm {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 25px 30px;

      @include breakpoint-600 {
        grid-template-columns: 1fr;
      }
    }

    &-AddressForm {
      display: grid;
      gap: 25px 30px;
      grid-template-columns: repeat(7, 1fr);
      grid-template-areas: 'str-no     str-name   str-name   str-name   apt        apt        apt'
                           'country    country    state      state      city       city       zip';

      @media screen and (max-width: 1100px) {
        grid-template-columns: repeat(6, 1fr);
        grid-template-areas: 'str-no     str-no     str-name   str-name   str-name   str-name'
                             'apt        apt        apt        apt        zip        zip'
                             'country    country    state      state      city       city';
      }
    }

    label {
      font-weight: 500;
    }

    input.form-control::placeholder {
      color: #acacac !important;
    }

    select.form-control,
    input.form-control {
      background-image: none !important;
    }

    // Grid Areas
    ._grid-area-str-no {
      grid-area: str-no;
    }

    ._grid-area-str-name {
      grid-area: str-name;
    }

    ._grid-area-apt {
      grid-area: apt;
    }

    ._grid-area-zip {
      grid-area: zip;
    }

    ._grid-area-country {
      grid-area: country;
    }

    ._grid-area-state {
      grid-area: state;
    }

    ._grid-area-city {
      grid-area: city;
    }
  }

  // Keyword Page
  &-Keyword {
    display: grid;
    gap: 5px 15px;
    grid-template-columns: min-content auto;
    grid-template-areas: 'label input'
                         '. feedback';

    @include breakpoint-600 {
      grid-template-columns: 1fr;
      grid-template-areas: 'label'
                           'input'
                           'feedback';
    }

    &-Label {
      grid-area: label;
      display: flex;
      align-items: center;
      width: fit-content;
      white-space: nowrap;
    }
    &-Input {
      max-width: 300px;
      grid-area: input;
    }
    &-Feedback {
      grid-area: feedback;
    }
    &-Button {
      grid-area: button;
    }
  }

  // Try page
  &-Try {

    &-Message {
      display: flex;
      flex-flow: column;
      justify-content: center;
      margin: auto;
      padding: 10px;
      border-radius: 8px;
      text-align: center;
      height: 100%;
      // background: #e6e6e6;
      color: #fff;
      background: $color-primary;

      .mat-progress-spinner {
        margin: 15px auto;
        &::ng-deep circle {
          stroke: $color-primary;
        }
      }

      ion-icon {
        display: block;
        font-size: 48px;
        text-align: center;
      }

      &._success {
        ion-icon {
          color: $color-success;
        }
      }

      &._timedout {
        color: #333;
        background: #e6e6e6;
        ion-icon {
          color: $color-danger;
        }
      }
    }
  }

  // Contacts page
  &-Contacts {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 25px 30px;

    & + & {
      margin-top: 15px;
    }

    @include breakpoint-600 {
      grid-template-columns: 1fr;
    }

    @include breakpoint-1024 {
      grid-column: 1 / span 2;
    }

    label {
      font-weight: 500;
    }

    &._has-border {
      background-color: #fff;
      border: 1px solid #eaeaea;
      border-radius: 6px;
      padding: 10px;
    }

    &-Title {
      font-size: 24px;
      font-weight: normal;
      padding-bottom: 10px;
      margin-bottom: 15px;

      .SMSOnboarding-Contacts + & {
        margin-top: 30px;
      }
    }

    &-Span {
      grid-column: 1 / span 3;
    }
  }

  // Terms of Service page
  &-Tos {

    &-Textarea {
      position: relative;
      display: block;
      width: 100%;
      max-width: 100%;
      padding: 10px;
      height: 110px;
      min-height: 110px;
      min-width: 50%;
      border-radius: 8px;
      border: 1px solid #ced4da;
      background: #fff;
      overflow-y: scroll;
      outline: none !important;
      resize: auto;
      @include custom-scrollbar;
    }

    pf-copy-button {
      position: sticky;
      bottom: 0;
      float: right;
    }

  }
}

.SMSOnboarding-ConfigCard {
  border-radius: 1px;
  margin-bottom: 15px;
  overflow: hidden;
  box-shadow: 4px 4px 8px rgba(189, 136, 136, 0.08);

  &-Head {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    padding: 10px;
    color: $color-primary;
    background-color: #fff;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    transition: 0.2s;

    &._hoverable {
      &:hover {
        background-color: #0095f7;
        color: #fff;
      }
      &:hover .card-control img {
        filter: brightness(0) saturate(100%) invert(100%)
        sepia(1%) saturate(2%) hue-rotate(174deg) brightness(119%) contrast(100%);
      }
      &:hover ._tooltip {
        color: #50d73f;
        background: #fff;
      }
    }

    &._invert {
      background-color: #0095f7;
      color: #fff;

      & .card-control img {
        filter: brightness(0) saturate(100%) invert(100%)
        sepia(1%) saturate(2%) hue-rotate(174deg) brightness(119%) contrast(100%);
      }

      & ._tooltip {
        color: #50d73f;
        background: #fff;
      }
    }

    .card-control {
      display: flex;
      align-items: center;
      flex: 1;
      cursor: default;
      font-weight: bold;
      padding-left: 0.25rem;

      & ion-icon {
        font-size: 18px;
        line-height: 18px;
        margin-right: 10px;
        cursor: pointer;
        transition: all 0.15s;
        transform: rotate(-90deg);
      }
      & ._rotated {
        transform: rotate(0deg);
      }
    }
  }

  &-Body {
    font-size: 14px;
    background: #fff;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    overflow: hidden;
    border: 1px solid #ebeef2;
    border-top: none;

    a {
      color: #0095f7;
    }
  }

  &-BodyLine {
    padding: 20px;
    border-top: none;
  }
}

.SMSOnboarding-Copy {
  position: relative;
  width: fit-content;
  min-width: 180px;
  padding: 6px;
  margin: 4px 0;
  border-radius: 6px;
  border: 1px solid #e6e6e6;

  pf-copy-button {
    position: absolute;
    top: 2px;
    right: 2px;
    opacity: 0;
    background: #fff;
  }

  &:hover {
    pf-copy-button {
      opacity: 1;
    }
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

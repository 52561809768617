.ContentHeader {
  background: #f8f9fa;
  margin: -20px -20px 20px;
  padding: 20px;
  border-bottom: 1px solid #f4f6f7;
  &-Title {
    font-size: 15px;
    color: #555;
    font-weight: 500;
    line-height: 20px;
    margin: 0;
  }
}

.Dot {
  display: inline-block;
  text-transform: capitalize;
  white-space: nowrap;
  &::before {
    content: '';
    display: inline-block;
    width: 13px;
    height: 13px;
    margin-right: 5px;
    border-radius: 50px;
    border: 1px solid #e6e6e6;
    background: #ccc;
  }

  &._scheduled::before {
    background-color: $color-info;
  }

  &._muted::before {
    background-color: $color-secondary;
  }

  &._failed::before,
  &._bounced::before,
  &._excluded::before,
  &._complaint::before {
    background-color: $color-danger;
  }

  &._paused::before,
  &._waiting::before {
    background-color: $color-warning;
  }

  &._active::before,
  &._sending::before,
  &._connected::before,
  &._sent::before,
  &._delivered::before {
    background-color: $color-success;
  }

  &._failed {
    cursor: pointer;
  }
}

html {
  height: 100%;
}
body {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: 'banner'
                       'content';
  height: 100%;
  width: 100%;
}

.L-App {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: start;
  height: 100%;
  background: #f6f6f7;
  @include breakpoint-660 {
    display: block;
  }

  &._bar {
    &._nav-forced,
    &._nav-pinned {
      .L-App-Aside {
        flex: 0 0 calc(var(--sidenav-full-width) + var(--page-padding));
      }
    }
  }

  &-Body {
    display: flex;
    height: 100%;
    width: 100%;
    padding: var(--page-padding);
    overflow-y: auto;
    flex-flow: row-reverse wrap;
    align-items: center;
    align-content: flex-start;
    min-width: 0; // grid blowout fix
    @include custom-scrollbar;
    @include breakpoint-660 {
      padding: var(--header-height) 0 0;
    }
  }

  &-Title {
    font-size: 22px;
    margin-left: 0;
    margin-right: auto;

    @include breakpoint-660 {
      padding: var(--page-padding) 0 0 var(--page-padding);
    }
  }

  &-Nav {
    position: sticky;
    top: 0;
    flex: 0 1 auto; // added because search is temporary hidden
    max-width: 764px;
    min-width: min-content;
    z-index: 2000;

    @include breakpoint-660 {
      position: absolute;
    }
  }

  &-Aside {
    position: sticky;
    top: 0;
    padding: var(--page-padding) 0 var(--page-padding) var(--page-padding);
    flex: 0 0 calc(var(--sidenav-width) + var(--page-padding));
    height: 100%;
    transition: flex 0.3s;
    z-index: 2001;

    @include breakpoint-660 {
      padding: 0;
      position: absolute;
    }

    &._right {
      width: 24px;
      top: calc(60px + 24px);
      height: calc(100vh - 36px - 60px);
    }

    &._expanded-nav {
      width: 215px;
    }
    &._expanded-chat {
      width: 275px;
    }
    &._expanded-chat-lg {
      width: 35vw;
    }

    &._mobile-open {
      .side-backdrop {
        width: 100vw;
        height: 100vh;
        opacity: 1;
      }
    }
  }

  &-Content {
    flex: none;
    width: 100%;
    height: 100%;
    margin-top: var(--page-padding);
    // FIXME: this is causing the grid blowout on CDP page
    // display: grid;
    // grid-template-columns: 1fr;
    // grid-template-columns: 1fr auto;
    // grid-gap: var(--page-padding);
    // min-width: 0; // grid blowout fix
    // router-outlet { // a hack to avoid the router-outlet from being treated as a grid item
    //   display: none;
    // }

    &._with-navbar {
      height: calc(100% - 54px); // subtract header height
    }
  }

  @include breakpoint-660 {
    padding: 0;
    &._bar {
      padding: 0;
    }
    &-Aside {
      left: -100%;
      top: 0;
      bottom: 0;
      transition: left 0.3s;

      &._mobile-open {
        left: 0;
      }

      .side {
        border-radius: 0 !important;
        one-main-logo {
          margin-left: 0 !important;
        }
        .side-pin {
          display: none;
        }
        .side-close {
          display: block;
        }
      }
    }
    &-Body {
    }
    &-Content {
      padding: 0 var(--page-padding);
    }
    &-Nav {
      top: 0;
      width: 100%;
      left: 0;
    }
  }

  &-Overlay {
    width: 100vw;
    height: 100vh;
    // overflow: auto;
    background: #fff;

    &._with-header {
      height: calc(100vh - 62px);
      top: 62px;
      .CouponCode-Summary {
        top: 80px;
      }
    }
  }
}

.cdk-overlay-container {
  z-index: 2001;
}
.cdk-global-overlay-wrapper {
  overflow-y: auto;
}

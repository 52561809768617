.SectionTitle {
  margin-bottom: 12px;
  line-height: 36px;
  font-size: 26px;

  &._sub {
    font-size: 22px;
  }

  @include breakpoint-660 {
    font-size: 24px;
    line-height: unset;
  }
  
  &-Logo {
    width: 36px;
    margin-top: -6px;
    margin-right: 8px;
    @include breakpoint-660 {
      display: none;
    }
  }

  &-Badges {
    @include breakpoint-660 {
      display: none;
    }
  }

  &-Controls {
    @include breakpoint-660 {
      // margin-top: 12px;
      // margin-bottom: 12px;
    }
  }

  &._has-controls {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    @include breakpoint-660 {
      // flex-flow: column nowrap;
      // margin-bottom: 0;
    }
  }
}

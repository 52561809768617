.Tooltip {
  width: 16px;
  height: 16px;
  display: inline-block;
  background: $color-primary-new;
  position: relative;
  color: #fff;
  font-size: 11.5px;
  font-weight: 500;
  border-radius: 50%;
  margin-left: 2px;
  margin-bottom: -2px;
  line-height: 16px;
  &::after {
    font-family: FontAwesome;
    content: '\f129';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &._metric {
    font-size: 14 * 0.75px;
    width: 16 * 0.75px;
    height: 16 * 0.75px;
    background: #a4abb3;
    vertical-align: middle;
  }
  &.xl {
    width: 20px;
    height: 20px;
    font-size: 19px;
    margin-bottom: -1px;
  }
}

.Tooltip-Dots {
  position: relative;
  border-bottom: 2px dotted #c4cdd5;
  width: fit-content;
  &:hover {
    cursor: pointer;
  }
}

.Tooltip-Dashed {
  position: relative;
  border-bottom: 1px dashed #c4cdd5;
  width: fit-content;
  &:hover {
    cursor: pointer;
  }
}

.Tooltip-Box {
  color: #000 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  padding: 16px !important;
  line-height: 20px !important;
  background: #fff;
  border-radius: 8px !important;
  box-shadow: rgba(23, 24, 24, 0.08) 0 0 6px -3px, rgba(23, 24, 24, 0.12) 0 3px 20px -4px!important;
}

.FormField {
  margin: 0 -20px;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #f5f5f5;

  &-Label {
    margin: 0;
    max-width: 50%;
    min-height: 41px;
    padding: 10px 0;
    color: #4b4b4b;
    font-size: 14px;
    line-height: 20px;
  }

  &-Control {
    width: 250px;
    max-width: 50%;
    &._tiny {
      width: 50px;
      padding: 5px;
      text-align: center;
    }
  }

  &._first {
    border-top: 0;
  }

  //&._secondary {
  //  padding: 5px 20px;
  //  background: #fafdff;
  //}

  //&._column {
  //  display: block;
  //}
  &._inline {
    flex-wrap: wrap;
    justify-content: start;
    > * {
      margin-right: 6px;
    }
  }
}

.inside-label {
  position: relative;
  label {
    position: absolute;
    background-color: #fff;
    box-shadow: 0 0 2px 1px #fff;
    font-family: 'Roboto', 'Helvetica Neue', sans-serif;
    margin: 0;
    color: #000;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    top: -10px;
    left: 5px;
    padding: 0 5px;
    z-index: 1;
  }
}

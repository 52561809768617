.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #fff;

  transform: scale(1);
  opacity: 1;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  margin: 24px;
  max-width: 33vw;
  min-width: 344px;
  padding: 14px 16px;
  min-height: 48px;
  transform-origin: center;
  .mdc-snackbar__surface {
    box-shadow: none !important;
  }
  .mat-mdc-snack-bar-label {
    padding: 0;
  }
}

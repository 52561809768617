@import 'proof-factor/import';

.PluginList {
  width: 100%;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  margin: 0;
  padding: 20px;

  @include breakpoint-992 {
    grid-template-columns: repeat(1, 1fr);
  }

  &._popups {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    @include breakpoint-1200 {
      grid-template-columns: repeat(2, 1fr);
    }
    @include breakpoint-992 {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

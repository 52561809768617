.CardTitle {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #555555;
  padding: 15px 20px;
  background: #f8f9fa;
  border-bottom: 1px solid #e4e8eb;
  border-top: 1px solid #e4e8eb;

  .Card & {
    margin-right: -20px;
    margin-left: -20px;
  }

  .Card-Body &:first-child {
    margin-top: -20px;
  }
}

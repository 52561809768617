@import 'proof-factor/import';

$campaign-navigation-color: #c4c4c4;

// Old styles are still used on review campaign page, need to delete after new design will be available
.CampaignNavigation {
  .old & {
    &-Item {
      position: relative;
      transition: 0.2s;

      &::after {
        content: '';
        position: absolute;
        height: calc(100% - 20px - 10px);
        width: 2px;
        left: 11px;
        top: 24px + 5px + 10px;
        background: $campaign-navigation-color;
      }

      &:last-child::after {
        display: none;
      }
    }

    &-Link {
      display: flex;
      font-size: 15px;
      text-decoration: none;
      padding: 12px 0;
      transition: 0.2s;

      &:hover {
        text-decoration: none;
      }
    }

    &-Counter {
      flex: 24px;
      min-width: 24px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: $campaign-navigation-color;
      color: #fff;
      font-size: 12px;
      line-height: 24px;
      text-align: center;
      margin-right: 10px;
      transition: 0.2s;
    }

    &-Label {
      flex: 100%;
      color: #000;
      transition: 0.2s;
    }

    &-New-Feature {
      font-size: 10px;
      font-weight: bold;
      border-radius: 10px;
      background: #50d73f;
      color: #ffffff;
      padding: 0 8px;
      height: 18px;
      line-height: 18px;
      text-transform: uppercase;
    }

    &-Required-Asterisk {
      color: #f00 !important;
      font-weight: bold;
    }

    &-Tooltip {
      display: inline-block;
      background: #0095f7;
      position: relative;
      color: #ffffff;
    }

    &-SubMenu {
      padding-left: 30px;
      padding-bottom: 10px;
    }

    &-SubItem {
      margin: 10px 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-SubLink {
      font-size: 12px;
      line-height: 18px;

      &:hover {
        text-decoration: none;
      }
    }

    &-SubLabel {
      color: #7c8c98;
      display: block;
      transition: color 0.2s;

      &:hover {
        color: $color-primary;
      }
    }

    &-Item {
      &:hover {
        .CampaignNavigation-Counter {
          background: $color-primary;
        }
        .CampaignNavigation-Label {
          color: $color-primary;
        }
        &::after {
          background: $campaign-navigation-color;
        }
      }

      &._current {
        .CampaignNavigation-Counter {
          background: $color-primary;
        }
        .CampaignNavigation-Label {
          color: $color-primary;
        }
        &::after {
          background: $campaign-navigation-color;
        }
      }

      &._done {
        .CampaignNavigation-Counter {
          background: #333;
        }
        .CampaignNavigation-Label {
          color: #333;
        }
        // The regular items has hover effects now
        &:hover {
          .CampaignNavigation-Counter {
            background: $color-primary;
          }
          .CampaignNavigation-Label {
            color: $color-primary;
          }
        }
        &::after {
          background: #333;
        }
      }
    }
  }
}

// New styles
.CampaignNavigation {
  counter-reset: navigation;
  .new & {
    &-Item {
      position: relative;
      transition: 0.2s;
      counter-increment: navigation;

      // The regular items has hover effects now
      &:hover {
        .CampaignNavigation-Counter {
          background: $color-primary;
        }
        .CampaignNavigation-Label {
          color: $color-primary;
        }
      }

      &::after {
        content: '';
        position: absolute;
        height: calc(100% - 20px - 10px);
        width: 2px;
        left: 10px;
        top: 44px;
        background: #333;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.1s ease-in-out;
      }
      &._step-line_visible::after {
        visibility: visible;
        opacity: 1;
      }

      &:last-child::after {
        display: none;
      }
    }

    &-Link {
      display: flex;
      align-items: center;
      font-size: 15px;
      text-decoration: none;
      transition: color 0.2s;
      justify-content: center;
      height: 58px;


      &:hover {
        text-decoration: none;
      }

      &._expanded {
        justify-content: flex-start;
      }
    }

    &-Counter {
      min-width: 24px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: #333;
      color: #fff;
      font-size: 12px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
      margin-bottom: 10px;

      & ion-icon {
        font-size: 16px;
      }

      &._expanded {
        margin: 0 10px 0 0;

      }
    }

    &-Label {
      display: flex;
      color: #000;
      font-size: 15px;
      font-weight: 400;
      text-align: center;
    }

    &-New-Feature {
      font-size: 10px;
      font-weight: bold;
      border-radius: 10px;
      background: #50d73f;
      color: #ffffff;
      padding: 0 8px;
      height: 18px;
      line-height: 18px;
      text-transform: uppercase;
    }

    &-Required-Asterisk {
      color: #f00 !important;
      font-weight: bold;
      margin-left: 5px;
    }

    &-Tooltip {
      display: inline-block;
      background: #0095f7;
      position: relative;
      color: #ffffff;
      z-index: 999;
    }

    &-SubMenu {
      padding-bottom: 10px;
    }

    &-SubItem {
      margin: 10px 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-SubLink {
      font-size: 12px;
      line-height: 18px;

      &:hover {
        text-decoration: none;
      }
    }

    &-SubLabel {
      color: #7c8c98;
      display: block;
      transition: color 0.2s;

      &:hover {
        color: $color-primary;
      }
    }


    &-Item._current {
      .CampaignNavigation-Counter {
        background: $color-primary;
        // Checkmark icon is hidden
        ion-icon {
          display: none;
        }
        // Counter Visible
        &::after {
          content: counter(navigation);
        }
      }
      .CampaignNavigation-Label {
        color: $color-primary;
      }
      &::after {
        background: $campaign-navigation-color;
      }

      // All Items that goes after ._current is grey
      & ~ .CampaignNavigation-Item {
        .CampaignNavigation-Counter {
          background: $campaign-navigation-color;
          // Checkmark icon is hidden
          ion-icon {
            display: none;
          }
          // Counter Visible
          &::after {
            content: counter(navigation);
          }
        }
        .CampaignNavigation-Label {
          color: #333;
        }
        &::after {
          background: $campaign-navigation-color;
        }
        // Only grey items has hover effects
        &:hover {
          .CampaignNavigation-Counter {
            background: $color-primary;
          }
          .CampaignNavigation-Label {
            color: $color-primary;
          }
        }
      }
    }

    &-Item._error {

      & .CampaignNavigation-Counter {
        background: #f8646f !important;

        &::after {
          content: ''!important;
        }
        ion-icon {
          display: none;
        }
      }

      .CampaignNavigation-Label {
        color: #f8646f !important;
      }

      ._local-label {
        color: #f8646f !important;
      }
    }
  }
}

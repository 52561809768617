.DesignField {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid rgba(#92a2ad, 0.2);
  &._title {
    border-top: 1px solid #e6edf3;
    background: #e6f0f97a;
    color: #405d75;
    font-weight: 500;
  }

  .block-subtitle {
    background: #fafdff;
    border-bottom: 1px solid #eef5fb;
    border-top: 1px solid #e6edf3;
    color: #67839b;
    margin: 0 -20px;
    padding: 10px 20px;
  }

  &-Label {
    line-height: 20px;
    font-size: 14px;
    color: #4b4b4b;
    font-weight: 600;
    margin: 0;
  }

  &-Input {
    position: relative;
    z-index: 0;
    width: 100%;
    max-width: 160px;
    &._extra-width {
      max-width: 195px;
    }
    &.mat-mdc-select {
      padding: 8px;
    }
    &._switchBox {
      text-align: right;
      pf-switch-box {
        display: inline-block;
      }
    }
  }
}

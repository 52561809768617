$chbx_size: 20px;
$chbx_text_gap: 10px;
$chbx_fill_color: #ffffff;
$chbx_border_color: #bdc6cf;
$chbx_border_color_checked: $color-primary;
$chbx_v_Wsize: 13px;
$chbx_v_Hsize: 8px;
$chbx_v_Ypos: 4px;
$chbx_v_Xpos: 4px;
$chbx_v_color: $color-primary;

.checkbox {
  user-select: none !important;
  margin-bottom: 0;

  input {
    display: none;
  }

  & > span:first-of-type {

    position: relative;
    display: inline-block;
    vertical-align: middle;
    min-height: $chbx_size;
    min-width: $chbx_size;
    line-height: $chbx_size;
    padding-left: $chbx_size + $chbx_text_gap;
    cursor: pointer;

    &:empty {
      padding-left: 0;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: $chbx_size;
      height: $chbx_size;
      background: rgba($chbx_fill_color, 0);
      box-shadow: inset 0 0 0 1px rgba($chbx_border_color, 1);
      border-radius: 3px;
      // transition: all 0.1s cubic-bezier(0.64, 0.57, 0.67, 1.53);
    }

    &.invalid {
      &::before {
        box-shadow: inset 0 0 0 1px rgba($color-danger, 1);
      }
    }

    &::after {
      content: '';
      display: block;
      width: $chbx_v_Wsize;
      height: $chbx_v_Hsize;
      position: absolute;
      top: $chbx_v_Ypos;
      left: $chbx_v_Xpos;
      border: 2px solid;
      border-color: transparent transparent $chbx_v_color $chbx_v_color;
      transform: scale(5) rotate(-45deg);
      opacity: 0;
      pointer-events: none;
      // transition: all 0.1s cubic-bezier(0.64, 0.57, 0.67, 1.53);
    }

  }

  input:checked + span {
    &::after {
      opacity: 1;
      border-color: transparent transparent $chbx_v_color $chbx_v_color;
      // box-shadow: inset 1px -1px 0 $chbx_v_color;
      transform: scale(1) rotate(-45deg);
    }
    &::before {
      box-shadow: inset 0 0 0 1px rgba($chbx_border_color_checked, 1);
      background: rgba($chbx_fill_color, 1);
    }
  }

  input:indeterminate + span {
    &::after {
      opacity: 1;
      border-color: transparent transparent $chbx_v_color;
      border-left: 0;
      border-right: 0;
      transform: scale(1);
    }
    &::before {
      box-shadow: inset 0 0 0 1px rgba($chbx_border_color_checked, 1);
      background: rgba($chbx_fill_color, 1);
    }
  }
}

.checkbox._solid {
  span::before {
    background: rgba($chbx_fill_color, 1);
  }
}
.checkbox._disabled {
  pointer-events: none;
  span._disabled {
    color: #b3b3b3;
  }
  span:first-of-type {
    &::after {
      border-color: transparent transparent $chbx_border_color $chbx_border_color;
    }
  }
  input:checked + span {
    &::after {
      border-color: transparent transparent $chbx_border_color $chbx_border_color;
    }
    &::before {
      box-shadow: inset 0 0 0 1px rgba($chbx_border_color, 1);
    }
  }
}

.checkbox._animated {
  span {
    &::after {
      transition: all 0.1s cubic-bezier(0.64, 0.57, 0.67, 1.53);
    }
    &::before {
      transition: all 0.1s cubic-bezier(0.64, 0.57, 0.67, 1.53);
    }
  }
}

.mat-checkbox-wrap {
  .mat-checkbox-layout {
    white-space: normal !important;
    align-items: flex-start;
    .mat-checkbox-inner-container {
      margin: 4px 10px 0 0;
    }
  }

}

$plan-info-text-color: #19293d;
$plan-info-text-hover-color: #0095f7;
.PlanInfo {
  cursor: pointer;
  max-width: 130px;
  height: $header-height;
  @include breakpoint-lg-alt {
    max-width: initial;
    // min-width: 200px;
  }


  &._credits {
    cursor: default;
    max-width: initial;
    @include breakpoint-lg-alt {
      max-width: initial;
      // min-width: 200px;
    }
  }

  &-Text {
    color: $plan-info-text-color;
    text-align: center;
    font-size: 10px;
    line-height: 20px;
    transition: 0.2s;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @include breakpoint-lg-alt {
      font-size: 12px;
    }
  }
  &:hover &-Text {
    color: $plan-info-text-hover-color;
  }

  &._visitors-over-limit {
    height: fit-content;
  }

  &.plan-over-limit {
    height: fit-content;
    padding: 5px 20px;
    @include breakpoint-1300 {
      padding: 0;
    }
  }

  &._overLimit {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row nowrap;
    max-width: none;
    white-space: nowrap;
    padding: 2px 10px;
    color: #ffc107;
    font-weight: 500;
    font-size: 15px;
    text-transform: uppercase;

    button {
      margin-left: 15px;
      font-size: 14px;
      padding: 2px 10px !important;
      color: #fff;
      background: #f54866;
      &:hover {
        background: #f66880;
      }
      @include breakpoint-1100 {
        margin-left: 10px;
        font-size: 12px;
        padding: 2px 8px !important;
      }
    }

    ._error-wide {
      @include breakpoint-1380 {
        font-size: 13px;
      }
      @include between-900-1300 {
        display: none;
      }
      @include breakpoint-500 {
        display: none;
      }
    }

    ._error-icon {
      display: none;
      font-size: 33px;

      @include between-900-1300 {
        display: inline-block;
      }
      @include breakpoint-500 {
        display: inline-block;
      }
      @include breakpoint-1100 {
        font-size: 25px;
      }
    }
  }


  &-Credits {
    font-size: 12px;
    display: grid;
    height: 100%;
    white-space: nowrap;
    grid-template-columns: 1fr;

    &._one-col {
      grid-template-columns: 1fr;
    }

    @include between-900-1300 {
      grid-template-columns: auto;
    }
    @include breakpoint-900 {
      justify-content: center;
      grid-template-columns: 90px;
    }
    @include breakpoint-380 {
      gap: 0 5px;
      grid-template-columns: 1fr;
    }

    &._expandable {
      position: relative;
      one-active-campaign-dropdown {
        position: absolute;
        top: 100%;
        left: -151px;
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
      }
      &:hover {
        one-active-campaign-dropdown {
          visibility: visible;
          pointer-events: auto;
          transform: scale(1);
          opacity: 1;
        }
      }
    }


    &-Cell {
      &:not(._cursor-default) {
        cursor: pointer;
      }
      &._cursor-default {
        cursor: default;
      }
      justify-self: center;
      align-self: center;
      padding: 10px 5px;

      @include between-900-1300 {
        padding: 2px 10px;
      }

      &:not(._cursor-default):hover {
        .PlanInfo-Credits-Label {
          color: $color-primary;
        }
      }
      &._multiline {
        padding: 5px;
        .PlanInfo-Credits-Label {
          white-space: break-spaces;
          line-height: 0.9rem;
          font-size: 12px;
        }
      }
    }

    &-Label {
      font-size: 13px;
      text-align: center;
      color: #000;

      // @include between-900-1300 {
      //   display: none;
      // }
      // @include breakpoint-380 {
      //   display: none;
      // }
    }
    &-Value {
      text-align: center;
      color: #333;
      svg {
        margin-top: -1px;
      }
      @include between-900-1280 {
        &:not(._nohover):hover {
          color: $color-primary;
        }
      }
      @include breakpoint-380 {
        &:not(._nohover):hover {
          color: $color-primary;
        }
      }
    }

  }

}

.PreviewBlock {
  height: 100%;

  &__Title {
    display: none;
  }

  &__Box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 150px;
    min-width: 420px;
    z-index: 0;
    margin: 0 auto;
  }
}

@import 'proof-factor/import';

@mixin no-hover($color, $background) {
  &.no-hover {
    &:hover {
      color: $color;
      background: $background;
    }
  }
}

//button common styles
.one-button {
  font-size: 16px;
  border: 1px solid transparent;
  padding: 10px 20px;
  border-radius: 5px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: 0.2s;
  min-width: 36px;
  position: relative;
  font-weight: 600;
  white-space: nowrap;

  // full width
  &.fw {
    width: 100%;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  user-select: none;
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  &:active {
    transform: scale(0.93);
    outline: none;
    /**
    This have to be places here case of on button click it scales, become smaller and click on its border do not
    triggers event, so ::after becomes bigger after :active and in this case it plays role of button block and
    triggers event
     */
    &::after {
      transform: scale(1.1);
    }
  }
  &:focus,
  &:hover {
    outline: none;
    text-decoration: none;
  }

  &[disabled],
  &._disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }

  //button types styles
  &.filled {
    &.blue {
      color: #ffffff;
      background-color: $color-primary-new;
      border-color: $color-primary-new;
      &:hover {
        color: $color-primary-new;
        background: transparent;
      }
      @include no-hover(#ffffff, $color-primary-new);
    }
    &.red {
      color: #fff;
      background-color: $color-danger;
      border-color: $color-danger;
      &:hover {
        color: $color-danger;
        background-color: transparent;
      }
    }
    &.yellow {
      color: #fff;
      background-color: $color-warning;
      border-color: $color-warning;
      &:hover {
        color: $color-warning;
        background-color: transparent;
      }
    }
    &.green {
      color: #fff;
      background-color: $color-success;
      border-color: $color-success;
      &:hover {
        color: $color-success;
        background-color: transparent;
      }
    }
    &.gray {
      color: #fff;
      background-color: $color-secondary;
      border-color: $color-secondary;
      &:hover {
        color: $color-secondary;
        background: transparent;
      }
    }
    &.indigo {
      color: #fff;
      background-color: $color-shopify-indigo;
      border-color: $color-shopify-indigo;
      &:hover {
        color: $color-shopify-indigo;
        background: transparent;
      }
    }
    &.purple {
      color: #fff;
      background-color: $color-one-purple;
      border-color: $color-one-purple;
      &:hover {
        color: $color-one-purple;
        background: transparent;
      }
    }
    &.shopify-green {
      color: #fff;
      background-color: $color-shopify-green;
      border-color: $color-shopify-green;
      &:hover {
        color: $color-shopify-green;
        background-color: transparent;
      }
    }
    &.alt-green {
      color: #fff;
      background-color: $color-success-alt;
      border-color: $color-success-alt;
      &:hover {
        color: $color-success-alt;
        background-color: transparent;
      }
    }
    &.transparent {
      color: $color-secondary;
      background: none;
      &:hover {
        color: darken($color-secondary, 10%);
        border: 1px solid darken($color-secondary, 10%);
      }
    }
    &.black {
      color: #fff;
      background-color: $black;
      border-color: $black;
      &:hover {
        color: $black;
        background-color: transparent;
      }
    }
  }
  &.outline {
    &.blue {
      color: $color-primary-new;
      background-color: transparent;
      border-color: $color-primary-new;
      &:hover {
        color: #fff;
        background-color: $color-primary-new;
      }
    }
    &.red {
      color: $color-danger;
      background-color: transparent;
      border-color: $color-danger;
      &:hover {
        color: #fff;
        background-color: $color-danger;
      }
    }
    &.yellow {
      color: $color-warning;
      background-color: transparent;
      border-color: $color-warning;
      &:hover {
        color: #fff;
        background-color: $color-warning;
      }
    }
    &.green {
      color: $color-success;
      background-color: transparent;
      border-color: $color-success;
      &:hover {
        color: #fff;
        background-color: $color-success;
      }
    }
    &.gray {
      color: $color-secondary;
      background-color: transparent;
      border-color: $color-secondary;
      &:hover {
        color: #fff;
        background-color: $color-secondary;
      }
    }
    &.indigo {
      color: $color-shopify-indigo;
      background-color: transparent;
      border-color: $color-shopify-indigo;
      &:hover {
        color: #fff;
        background: $color-shopify-indigo;
      }
    }
    &.black {
      color: $black;
      background-color: #fff;
      border-color: $black;
      &:hover {
        color: #fff;
        background-color: $black;
      }
    }
    &.purple {
      color: $color-one-purple;
      background-color: transparent;
      border-color: $color-one-purple;
      &:hover {
        color: #fff;
        background: $color-one-purple;
      }
    }
  }

  // sizes
  &.xxs {
    font-size: 12px;
    border-width: 1px;
    padding: 3px 6px;
    line-height: 14px;
  }
  &.xs {
    font-size: 13px;
    border-width: 1px;
    //line-height: 20px;
    padding: 4px 10px;
  }
  &.sm {
    font-size: 14px;
    border-width: 1px;
    line-height: 20px;
    padding: 7px 16px;
  }
  &.md {
    font-size: 16px;
    border-width: 1px;
    line-height: 20px;
    padding: 12px 20px;
  }
  &.md2 {
    font-size: 16px;
    border-width: 1px;
    line-height: 16px;
    padding: 10px 20px;
    width: 180px;
  }
  &.lg {
    font-size: 18px;
    border-width: 1px;
    line-height: 20px;
    padding: 14px 24px;
  }
}

// gets rid of focus outline on buttons in most places
button:focus,
.close:focus,
.close span:focus {
  outline: none;
}

.one-options-button {
  display: inline-block;
  width: 35px;
  height: 35px;
  font-size: 20px;
  text-align: center!important;
  border-radius: 50%;
  line-height: 32px;
  cursor: pointer;
  color: #000;
  transition: all 180ms;

  &:hover {
    color: rgba(29, 155, 240);
    background: rgba(29, 155, 240, 0.1);
  }

  ion-icon {
    padding-bottom: 0;
  }
}

@import 'proof-factor/import';

.L-Campaign {
  height: 100%;

  @include breakpoint-576 {
    display: flex;
    flex-flow: nowrap column;
    min-height: auto;
  }

  $footer-and-header-background: #edf1f3;

  &-Header {
    padding-bottom: 20px;
    @include breakpoint-576 {
      padding: 20px 0;
    }
  }

  &-Navigation {
  }

  &-ContentTitle {
    margin: -20px -20px 20px;
    padding: 20px;
    border-bottom: 1px solid #ebeef2;
  }

  &-Content {
    flex: 100%;
    margin-bottom: 10px;
    @media screen and (min-width: 1024px) {
      height: calc(100% - 76px); // subtract footer height
      margin-bottom: 0;
    }
    &._with-bread-crumbs {
      display: grid;
      grid-template-rows: 60px max-content;
    }

    &._scrollable {
      max-height: calc(100vh - 80px);
      min-height: calc(100vh - 80px);
      overflow-y: scroll;

      &._mobile-device {
        min-height: unset;
      }

      @include custom-scrollbar();
    }

  }

  &-Footer {
    //border-top: 2px solid #e1e1e1;
    background: #fff;
    box-shadow: 0 2px 12px 0 #dfe3eb;
    padding: 10px;
    //margin-left: 20px;
    //margin-bottom: 20px;
    border-radius: 5px;
    //position: fixed;
    position: sticky;
    bottom: 0;
    //left: 0;
    width: 100%;
    //z-index: 100;
    margin-bottom: 0;
    margin-top: auto;
    z-index: 10;
    .Button {
      margin: 0 5px;
    }

    &._mobile-device {
      margin-top: 10px;
    }
  }

  &-FooterBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }


  &-Row {
    //margin: 20px 0;
    width: 100%;
    height: 100%;
    @include breakpoint-md {
      display: flex;
    }
  }

  &-Column {
    @include breakpoint-md {
      margin-right: 10px;
    }

    &:last-child {
      margin-right: 0;
    }

    &._side {
      max-height: calc(100% - 40px);
      overflow-y: auto;
      @include custom-scrollbar();

      > ._sticky {
        @include breakpoint-md {
          // position: fixed;
          // top: 80px;
          width: 200px;
        }
      }
      @include breakpoint-576 {
        max-height: none;
      }

    }

    &._content {
      //border-left: 2px solid #dfe3eb;
      //padding: 20px 0;
      display: flex;
      flex-flow: nowrap column;

      &._mobile-device {
        min-height: unset;
      }

      .L-Campaign._has-menu & {
        min-height: calc(100vh - #{$header-height + 40});
      }
      //margin-left: 80px;
      @include breakpoint-md {
        flex: 1;
        & > ng-component {
          flex: 1;
        }
        & > pf-plugin-configure-aggregate-visitor-count,
        & > pf-plugin-configure-activity,
        & > pf-plugin-configure-fortune-wheel,
        & > pf-recent-activity-plugin,
        & > pf-plugin-configure-live-visitor-count,
        & > pf-coupon-box-plugin,
        & > pf-info-popup,
        & > pf-newsletter-plugin {
          flex:1;
          display: flex;
          flex-flow: nowrap column;
          height: 100%;
        }
      }
    }

    &._mobile-device {
      min-height: unset;
    }
  }
}

.Wireframe {
  &-Phone {
    width: 264px;
    height: 533px;
    padding: 8px;
    border-radius: 25px;
    background: #f6f9fc;
    box-shadow: 0 14px 4px -8px rgb(50, 50, 93, 0.02),
                0 12px 29px -10px rgba(0, 0, 0, 0.3),
                inset 0 -2px 6px 0 rgba(10, 37, 64, 0.35);

    &-Display {
      height: 100%;
      border-radius: 23px;
      background: #fff;
    }
  }
}

.SMS-View {
  display: grid;
  grid-template-rows: auto 1fr;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 14px;

  &-Head {
    padding: 15px;
    color: #555;
    border-bottom: 1px solid #ccc;
    span {
      font-weight: 600;
      color: #333;
    }
  }
  &-Body {
    position: relative;
    display: flex;
    width: 100%;
    flex-flow: column nowrap;
    justify-content: flex-end;
    padding: 15px;
  }
}

.SMS-Input {
  border: 1px solid #ccc;
  margin-top: 15px;
  min-height: 33px;
  padding: 5px 15px;
  border-radius: 15px;
  width: 100%;
  word-break: break-word;
}

.SMS-Message {
  position: relative;
  padding: 15px;
  text-align: right;
  background: #e6e6e6;
  border-radius: 8px;
  margin-top: 20px;
  margin-bottom: 10px;
  word-break: break-word;

  &::before {
    content: '';
    position: absolute;
    bottom: -20px;
    right: 10px;
    width: 0;
    height: 0;
    border: 10px solid;
    border-color: #e6e6e6 transparent transparent;
  }

  &._response {
    background: $color-primary;
    color: #fff;
    text-align: left;
    &::before {
      right: unset;
      left: 10px;
      border-color: $color-primary transparent transparent;
    }
  }
}

one-icon {
  &._eye {
    border-radius: 5px;
    &:hover {
      background-color: $eye-icon-hover-background;
      color: $eye-icon-hover-text;
    }
    &:active {
      background-color: $eye-icon-active-background;
      color: $eye-icon-active-text;
    }
  }
}

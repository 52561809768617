$themesCarouselTransition: all 180ms;

.L-ThemeCarousel {
  display: flex;
  flex-flow: row nowrap;
  // margin: 0 -20px -20px;
  height: calc(100% - 10px);
  background: #f6f6f7;

  @include breakpoint-600 {
    height: auto;
    max-height: none;
  }

  // &._mobile-device {
  //   max-height: 835px;
  // }

  @include breakpoint-600 {
    flex-direction: column;
    max-height: none;
  }

  &-Main {
    flex: 1 0 60%;
  }

  &-Aside {
    flex: 1 0 40%;
    overflow-x: hidden;
    overflow-y: auto;
    padding-left: 10px;
    background: #fff;
    //box-shadow: 0 2px 12px 0 #dfe3eb;
    @include custom-scrollbar();
    @include breakpoint-600 {
      margin-top: 15px !important;
      // FIXME: (Maybe) Make theme carousel a sliding sidebar
      // position: absolute;
      // top: 0;
      // left: 100%;
      // z-index: 100;
      // margin-top: 0 !important;
      // min-height: 250px;
      // padding-left: 0;
      // height: calc(100vh - 74px);
      // min-width: 300px;
    }

    &-StickyHeader {
      position: sticky;
      top: 0;
      z-index: 5;
    }
  }
}

.CarouselView {
  display: grid;
  grid-template-rows: 60px 1fr 50px;
  height: 100%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background: #494949;
  @include breakpoint-600 {
    grid-template-rows: 60px 1fr 95px;
  }

  &-Head {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: #fff;
    box-shadow: 0 10px 15px rgba(#000, 0.3);
  }

  &-Body {
    position: relative;
    min-width: 0;
    min-height: 0;
    overflow: hidden;

    /*
    These are old selectors of shared components:
      pf-preview-newsletter
      pf-preview-coupon
      pf-preview-wheel
    We now use Shared Modules instead with these selectors:
      pf-coupon-box-preview
      pf-newsletter-preview
      pf-fortune-wheel-preview
    Keeping the old ones just in case. Need to delete them at some point
    */
    pf-coupon-box-preview,
    pf-newsletter-preview,
    pf-preview-newsletter,
    pf-preview-coupon {
      height: 100%;
    }
    pf-fortune-wheel-preview,
    pf-preview-wheel {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &-Footer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background: #fff;
    @include breakpoint-1024 {
      justify-content: flex-end;
      button {
        font-size: 13px;
        padding: 6px 14px;
        margin-right: 0 !important;
        margin-left: 10px;
      }
    }
  }

  &-ViewSwitch {
    position: absolute;
    left: 5px;
    bottom: 5px;
    width: 100px;
    height: 32px;
    border: 1px solid #727272;
    border-radius: 6px;
    &-Item {
      display: inline-block;
      width: 50%;
      height: 100%;
      line-height: 29px;
      text-align: center;
      cursor: pointer;
      color: #a4abb3;
      background-color: rgba(0, 0, 0, 0);
      transition: $themesCarouselTransition;
      border-radius: 6px 0 0 6px;
      &._active,
      &:hover {
        color: #fff;
        background-color: rgba(0, 0, 0, 0.3);
        & img {
          filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(169deg) brightness(104%) contrast(102%);
        }
      }

      &:last-child {
        border-radius: 0 6px 6px 0;
        border-left: 1px solid #727272;
      }
    }

    @include breakpoint-1024 {
      position: relative;
      bottom: 0;
      left: 0;
      margin-right: auto;
    }
  }

  &-Arrows {
    display: flex;
    justify-content: space-between;
    align-items: center;
    pointer-events: none;
    min-width: 50%;
    z-index: 10;

    &-Item {
      pointer-events: all;
      font-size: 80px;
      cursor: pointer;
      color: #8a8a8a;
      transform: translateY(0);
      transition: $themesCarouselTransition;

      &:hover {
        color: darken(#8a8a8a, 10%);
      }
      &:active {
        transform: translateY(2px);
      }
    }

    &._top-pos {
      .CarouselView-Arrows-Item {
        font-size: 30px;
        &:hover {
          color: #000;
        }
      }
    }

    &._center-pos {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      padding: 0 20px 0 5px;
      transform: translateY(-50%);
      visibility: hidden;
      opacity: 0;
      transition: all 0.2s ease-in-out;
    }


  }

  &:hover .CarouselView-Arrows._center-pos {
    visibility: visible;
    opacity: 1;
  }
  & .preview-toggle-container {
    margin: 0 auto;
  }
}

.PreviewBlockBG {
  background: #f6f6f7;
}

.PreviewBlockHeader {
  position: sticky;
  top: 0;
  z-index: 10;
  color: #383838;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  background-color: white;
  height: 60px;
  margin: 0 10px;
  @include breakpoint-600 {
    margin-top: 10px;
  }
}

$progress-bar-background: #0194f766;
$progress-bar-value-background: #0095f7;
$progress-bar-over-limit-background: #ff4c6a;
$progress-bar-label-color: #ffffff;
$progress-bar-height: 16px;

//$plan-info-text-color: #19293d;
//$plan-info-text-hover-color: #0095f7;

.ProgressBar {
  height: $progress-bar-height;
  border-radius: $progress-bar-height * 0.5;
  background: $progress-bar-background;
  position: relative;
  overflow: hidden;

  &-Value {
    width: 0;
    height: 100%;
    border-radius: $progress-bar-height * 0.5;
    background: $progress-bar-value-background;
    &.over-limit {
      background: $progress-bar-over-limit-background;

    }
  }

  &-Label {
    color: $progress-bar-label-color;
    font-size: 12px;
    line-height: $progress-bar-height;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

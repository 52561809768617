.Capture {

  &-Content {
    background-color: #fff;
  }

  &-Cards {
    padding: 20px 30px;
    box-shadow: 0 2px 8px rgba(15, 38, 52, 0.08);

    p {
      margin-bottom: 24px;
    }

  }

  &-Title {
    padding: 10px 16px;
    color: #009dff;
    font-weight: 600;
    line-height: 24px;
    border-left: 4px solid #009dff;
    font-size: 16px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    margin: 0;

  }

  &Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 24px 40px;
    @include breakpoint('lg-down') {
      flex-flow: row wrap;
    }
    &-Cell {
      flex: 1 1 auto;
      &._img {
        img {
          width: 80px;
        }
        padding-right: 25px;
        flex: 0 1 auto;
        @include breakpoint('lg-down') {
          flex: 0 1 110px;
        }
        @include breakpoint('xs-down') {
          flex: 1 1 auto;
          text-align: center;
        }
      }
      &._title {
        h2 {
          color: #000;
          font-size: 22px;
        }
        padding-right: 30px;
        @include breakpoint('lg-down') {
          flex: 0 1 calc(100% - 110px);
          padding-right: 0;
        }
        @include breakpoint('xs-down') {
          flex: 1 1 auto;
          h2 {
            text-align: center;
          }
        }
        p {
          margin-bottom: 0;
        }
      }
      &._button {
        text-align: right;
        .pf-button {
          width: 110px;
          height: 40px;
          line-height: 16px;
        }
        @include breakpoint('lg-down') {
          text-align: left;
          padding-left: 110px;
        }
        @include breakpoint('xs-down') {
          text-align: center;
          padding-left: 0;
        }
      }
    }
  }

  &Divider {
    font-size: 18px;
    color: #333333;
    font-weight: 500;
    background: #f5f5f5;
    border-top: 1px solid #ebeef2;
    padding: 10px 20px;
    margin: 15px -20px;
  }

  &Cards-List {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(4, 240px);
    border-radius: 2px;

    @include breakpoint('xl-down') {
      grid-template-columns: 1fr 1fr;
    }
    @include breakpoint('lg-down') {
      grid-template-columns: 1fr;
    }
  }


  &Card {
    display: flex;
    flex-flow: column nowrap;
    min-height: 270px;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid rgba(217, 220, 222, 1);
    box-shadow: 0 2px 8px rgba(15, 38, 52, 0.08);

    &:hover {
      box-shadow: 0 0 8px rgba(0, 123, 255, 0.25);
      border: 1px solid transparent;
    }

    &-Image {
      height: 50px;

      img {
        max-width: 48px;
      }

    }

    &-Head {
      width: 100%;
      text-align: left;
      font-size: 22px;
      color: #333;
      margin: 16px 0 6px;
      font-weight: 600;

      @include breakpoint('xl-down') {
        font-size: 22px;
      }
      @include breakpoint('lg-down') {
        font-size: 18px;
      }
    }

    &-Body {
      width: 100%;
      text-align: left;
      line-height: 21px;
    }

    &-Footer {
      width: 100%;
      margin-top: auto;
      text-align: center;
      .pf-button {
        width: 160px;
        height: 32px;
        line-height: 14px;
      }
    }
  }
}

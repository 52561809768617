.TriggerButtonIcons {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin: 0 -2px;
  &-Item {
    display: block;
    cursor: pointer;
    height: 41px;
    width: 41px;
    border: 2px solid #dadfe6;
    padding: 2px;
    border-radius: 3px;
    margin: 2px;
    transition: all 180ms;

    svg {
      width: 100%;
      height: 100%;

      path {
        transition: all 180ms;
      }
    }

    &._active {
      border: 2px solid $color-primary !important;
    }

    &._is-custom {
      position: relative;
      background: #ebf7ff;
      display: flex;
      justify-content: center;
      align-items: center;
      
      .TriggerButtonIcons-Item-Close {
        position: absolute;
        right: -5px;
        top: -5px;
        width: 15px;
        height: 15px;
        text-align: center;
        line-height: 13px;
        font-size: 14px;
        border-radius: 50%;
        color: #fff;
        background: #0095f7;

        &:hover {
          background: #f44336;
        }
      }

      img {
        width: 100%;
        height: auto;
      }
    }
    
    &:hover {
      border: 2px solid lighten($color-primary, 20%);
    }
  }
}

// .TriggerButtonCustomIcon {
//   width: 100%;
//   border: 1px solid #c6c6c6;
//   background: #f5f5f5;
//   border-radius: 6px;
//   margin-bottom: 15px;
//   padding: 10px;


// }

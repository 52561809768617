@import 'proof-factor/import';

.Resolve,
a.Resolve {
  background: $color-danger;
  padding: 5px 10px;
  border-radius: 3px;
  color: #fff;
  display: inline-block;
  text-decoration: none;
  font-size: 12px;
  font-weight: bold;
  margin: 5px;
  transition: 0.2s;
  border: 0;

  &:hover {
    background: darken($color-danger, 10%);
    color: #fff;
  }

  &._warn {
    background: $color-warning;

    &:hover {
      background: darken($color-warning, 10%);
    }
  }

  &._primary {
    background: $color-primary;

    &:hover {
      background: darken($color-primary, 10%);
    }
  }
}

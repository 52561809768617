.mat-mdc-slide-toggle {
  &.one-toggle {
    --mdc-switch-track-height: 24px !important;
    --mdc-switch-track-width: 48px !important;
    --mdc-switch-track-shape: 50px !important;
    .mdc-switch.mdc-switch--unselected .mdc-switch__handle {
      transform: translateY(-50%) translateX(2px) !important;
    }
    .mdc-switch.mdc-switch--selected .mdc-switch__handle {
      transform: translateY(-50%) translateX(-2px) !important;
    }
    .mdc-switch.mdc-switch--selected::after {
      position: absolute;
      left: 6px;
      content: var(--mdc-switch-selected-label, '');
      color: #fff;
      font-size: 12px;
    }
    .mdc-switch.mdc-switch--unselected::after {
      position: absolute;
      right: 6px;
      content: var(--mdc-switch-unselected-label, 'Off');
      color: #fff;
      font-size: 12px;
    }
    --mdc-switch-disabled-selected-handle-color: transparent !important;
    --mdc-switch-disabled-unselected-handle-color: transparent !important;
    --mdc-switch-disabled-selected-track-color: transparent !important;
    --mdc-switch-disabled-unselected-track-color: transparent !important;
    --mdc-switch-unselected-focus-state-layer-color: transparent !important;
    --mdc-switch-unselected-pressed-state-layer-color: transparent !important;
    --mdc-switch-unselected-hover-state-layer-color: transparent !important;
    --mdc-switch-unselected-focus-track-color: #aeb4bb !important;
    --mdc-switch-unselected-hover-track-color: #aeb4bb !important;
    --mdc-switch-unselected-pressed-track-color: #aeb4bb !important;
    --mdc-switch-unselected-track-color: #aeb4bb !important;
    --mdc-switch-unselected-focus-handle-color: #fff !important;
    --mdc-switch-unselected-hover-handle-color: #fff !important;
    --mdc-switch-unselected-pressed-handle-color: #fff !important;
    --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff) !important;
    --mdc-switch-unselected-handle-color: #fff !important;
    --mdc-switch-selected-icon-color: #fff !important;
    --mdc-switch-disabled-selected-icon-color: #fff !important;
    --mdc-switch-disabled-unselected-icon-color: #fff !important;
    --mdc-switch-unselected-icon-color: #fff !important;
    --mdc-switch-selected-focus-state-layer-color: transparent !important;
    --mdc-switch-selected-handle-color: transparent !important;
    --mdc-switch-selected-hover-state-layer-color: transparent !important;
    --mdc-switch-selected-pressed-state-layer-color: transparent !important;
    --mdc-switch-selected-focus-handle-color: #fff !important;
    --mdc-switch-selected-hover-handle-color: #fff !important;
    --mdc-switch-selected-pressed-handle-color: #fff !important;
    --mdc-switch-selected-focus-track-color: #50d73f !important;
    --mdc-switch-selected-hover-track-color: #50d73f !important;
    --mdc-switch-selected-pressed-track-color: #50d73f !important;
    --mdc-switch-selected-track-color: #50d73f !important;
  }
  .mdc-form-field {
    display: flex;
    align-items: center;
    label {
      margin-left: 0.5em;
      margin-bottom: 0;
    }
  }
}

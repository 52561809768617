$card-padding: 15px;
$card-padding-lg: 24px;
$card-margin-bottom: 12px;
$card-radius: 5px;
$card-border-color: $separator;
$card-background: #fff;
$card-shadow-color: #dfe3eb;

$card-head-margin-bottom: 20px;

.Card {
  border-radius: $card-radius;
  border: 1px solid $card-border-color;
  background: $card-background;
  padding: $card-padding-lg;
  box-shadow: 0 2px 12px 0 $card-shadow-color;
  margin-bottom: $card-margin-bottom;

  &._scroll-x {
    overflow-x: auto;
  }

  &-Head {
    // margin: (-$card-padding-lg) (-$card-padding-lg) 0;
    // padding: $card-padding 0 0;
    min-height: 65px;
    // border-bottom: 1px solid #ebeef2;
    //margin-bottom: $card-head-margin-bottom;
    &._flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      @include breakpoint-md-alt{
      }
    }
    &._grid {
      padding: 10px 20px;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    &._larger {
      padding: $card-padding-lg;
    }
  }
  &-SubHead {
    margin: 0 (-$card-padding-lg);
    padding: $card-padding $card-padding-lg;
    border-bottom: 1px solid #ebeef2;

    &._no-title {
      padding: 0 !important;
      overflow: visible !important;
      border-bottom: none;
    }
  }
  &-Body {
    padding-top: $card-padding-lg;
  }
  &-Section {
    margin: 0 (-$card-padding-lg);
    padding: $card-padding-lg;

    & + .Card-Section {
      border-top: 1px solid #ebeef2;
    }
  }
  &-Foot {
    margin: 0 (-$card-padding-lg) (-$card-padding);
    padding: $card-padding-lg;
    border-top: 1px solid #ebeef2;
    //margin-top: $card-head-margin-bottom;
  }
  &-Footer {
    margin: 0 (-$card-padding-lg) (-$card-padding);
    padding: $card-padding-lg;
    border-top: 1px solid #ebeef2;
    margin-top: $card-padding-lg;
  }

}

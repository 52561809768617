:root {
  --color-primary: #1a73e8;
  --color-green: #20b57a;
  --color-success: #50d73f;
  --color-success-alt: #29cd8c;
  --color-danger: #ff4c6a;
  --color-warning: #ffa100;
  --color-info: #17a2b8;
  --color-secondary: #a5abb3;

  --color-primary-new: #14a5ff;
  --color-secondary-alt: #798c99;
  --color-secondary-alt-2: #dfe3ea;

  --color-shopify-indigo: #5c6ac4;
  --color-shopify-purple: #8463fd;
  --color-one-purple: #7f5cff;
  --color-shopify-blue: #006fbb;
  --color-shopify-green: #298160;
  --color-shopify-darkgreen: #020303;
  --color-shopify-red: #bf0711;

  --page-padding: 12px;
  --banner-height: 52px;
  --header-height: 42px;
  --sidenav-width: 60px;
  --bdrs: 8px;
  --sidenav-full-width: 200px;

  // Bootstrap related stuff
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 
  'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
}

@import 'import';

@import 'base/_reboot';
@import 'base/_bootstrap';
@import 'base/index';
@import 'layout/index';
@import 'blocks/index';
@import 'one/index';
@import 'helpers/index';

@import './shame';


@import '../proof-factor/mixins/custom-scrollbar';

.mat-mdc-dialog-container {
  position: relative;
  border-radius: 4px;
  .mat-mdc-dialog-surface {
    background: #fff;
    box-shadow: 0 11px 15px -7px #0003, 0 24px 38px 3px #00000024, 0 9px 46px 8px #0000001f;
    display: block;
    box-sizing: border-box;
    overflow: auto;
    outline: 0;
    padding: 24px;
  }
  /* Basic layout */
  [mat-dialog-title], .mat-dialog-title {
    //padding: 24px 24px 0;
    font: 500 20px/32px Roboto, Helvetica Neue, sans-serif;
    letter-spacing: normal;
  }
  [mat-dialog-content], .mdc-dialog-content {
    //padding: 0 24px;
    --mdc-dialog-supporting-text-font: Roboto, Helvetica Neue, sans-serif;
    --mdc-dialog-supporting-text-size: 14px;
    --mdc-dialog-supporting-text-line-height: 1.5;
    --mdc-dialog-supporting-text-weight: 400;
    --mdc-dialog-supporting-text-tracking: normal;
    --mdc-dialog-supporting-text-color: #000;
  }
  [mat-dialog-actions], .mat-dialog-actions {
    //padding: 0 24px 24px;
  }
  [mat-dialog-close], .mat-dialog-close {
    z-index: 1;
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
    cursor: pointer;
    background: none;
    border: none;
    outline: none !important;
    -webkit-appearance: none !important;
    &:hover {
      opacity: 0.75;
    }
  }
  .mdc-dialog__content {
    color: #000!important;
    line-height: unset!important;
    font-family: unset!important;
    letter-spacing: unset!important;
    padding: 0!important;
  }
  .mdc-dialog__title {
    position: static;
    letter-spacing: unset;
    padding: 0;
    margin: 0 0 20px;
    &::before {
      content: none;
    }
  }
}

[cdk-overlay-pane],
.cdk-overlay-pane {

  &._no-padding {
    [mat-dialog-container],
    .mat-mdc-dialog-surface {
      padding: 0;
    }
  }

  &._no-y-padding {
    [mat-dialog-container],
    .mat-mdc-dialog-surface {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &._no-x-padding {
    [mat-dialog-container],
    .mat-mdc-dialog-surface {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &._custom-scrollbar {
    [mat-dialog-content],
    mat-dialog-content,
    .mat-mdc-dialog-surface {
      @include custom-scrollbar;
    }
  }

  &._overflow-unset {
    [mat-dialog-container],
    .mat-mdc-dialog-surface,
    .mat-mdc-dialog-container {
      overflow: unset;
    }
  }
}

.theme-request-modal {
  [mat-dialog-container],
  .mat-mdc-dialog-container {
    //padding: 14px 24px;
  }
}

.material-dialog-fullscreen {
  width: 100vw !important;
  height: 100vh !important;
  max-width: 100vw !important;
  max-height: 100vh !important;

  ng-component {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    height: 100%;
  }
}

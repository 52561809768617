.PluginTitle {

  &-Box {
    flex: 0 0 240px;
    text-transform: uppercase;
    text-align: left;
    user-select: none;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  &-Icon {
    height: 48px;
    width: auto;
    margin-right: 5px;
  }

  &-Text {
    font-size: 22px;
    line-height: 24px;
    color: #333;
    font-weight: 500;
    margin: 0;

    & > small {
      display: block;
      font-size: 15px;
      line-height: 17px;
      color: #a5abb3;
      font-weight: 500;
    }
  }

  &-Description {
    font-size: 14px;
    line-height: 20px;
    color: #acb0b4;
    margin-top: 5px;
  }

  &._small {
    .PluginTitle-Icon {
      height: 32px;
    }

    .PluginTitle-Text {
      font-size: 16px;
      line-height: 17px;

      & > small {
        font-size: 13px;
        line-height: 14px;
      }
    }

  }

  &._xsmall {
    .PluginTitle-Icon {
      display: none;
    }

    .PluginTitle-Text {
      font-size: 12px;
      line-height: 17px;

      & > small {
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        color: #000;
        text-transform: capitalize;
        text-align: center;
      }
    }

  }
}

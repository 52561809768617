.CodeValue {
  margin-bottom: 20px;
  display: flex;
  flex-flow: column nowrap;
  @include breakpoint-md {
    flex-flow: row nowrap;
    //align-items: flex-start;
  }

  & + & {
    margin-top: -15px;
  }

  &-Code {
    padding: 10px 20px;
    border: 1px solid transparent;
    background: #f9f9f9;
    font-size: 12px;
    line-height: 20px;

    pre {
      margin-bottom: 0;
      color: #e83e8c;
      font-size: inherit;
      line-height: inherit;
    }

    @include breakpoint-md {
      flex: 1;
      margin-right: 0;
      margin-bottom: 0;
    }
  }

  &-Value,
  &-Error {
    border: 1px solid $separator;
    border-top: 4px solid $color-primary;
    padding: 10px 20px;
    box-shadow: 2px 2px 0 0 rgba($separator, 0.25);
    font-size: 14px;
    line-height: 20px;
    color: #788189;
  }

  &-Value {
    @include breakpoint-md {
      flex: 1;
      border: 1px solid $separator;
      border-left: 4px solid $color-primary;
    }
  }

  &-Error {
    flex: 100%;
    border: 1px solid $separator;
    border-top: 4px solid $color-danger;
    color: $color-danger;
  }
}

/* Mat-slider legacy code
/*
.mat-slider-horizontal {
  &.cdk-focused {
    .mat-slider-thumb-label-text {
      transform: rotate(0deg) !important;
    }

    .mat-slider-thumb {
      transform: scale(0.8) !important;
    }

    .mat-slider-thumb-label {
      width: 32px !important;
      border-radius: 3px !important;
      transform: rotate(0deg) !important;
      background: #000 !important;
    }
  }

  .mat-slider-thumb-label {
    right: -16px !important;
    transform-origin: 50% 50%;
    transform: translateY(26px) scale(0.01) rotate(0deg) !important;
    .mat-slider-thumb-label-text {
      line-height: 14px;
    }

    &::after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      bottom: -9px;
      left: 0;
      right: 0;
      margin: auto;
      border: 5px solid;
      border-color: #000 transparent transparent;
    }
  }
  &.wide-label {
    .mat-slider-thumb-label {
      width: 38px !important;
      text-align: center !important;
    }
  }
}
*/

/* Previous ng-deep code in social-proof-design.component.scss
/*
.mat-slider.mat-slider-horizontal .mat-slider-track-wrapper {
  height: 6px;
  border-radius: 10px;
}
.mat-slider.mat-slider-horizontal .mat-slider-track-background,
.mat-slider.mat-slider-horizontal .mat-slider-track-fill {
  height: 100%;
}
.mat-slider.mat-slider-horizontal .mat-slider-track-fill {
  background-color: #009dff;
}
.mat-accent .mat-slider-thumb {
  height: 15px;
  width: 15px;
  background-color: #009dff;
  border: solid 1px #fff;
  bottom: -10px;
  right: -10px;
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  background-color: white;
}
*
*/

.mdc-slider .mdc-slider__thumb {
  width: 46px !important;
  height: 46px !important;
  left: -30px !important;
  --mat-mdc-slider-ripple-color: transparent !important;
  --mat-mdc-slider-hover-ripple-color: transparent !important;
  --mat-mdc-slider-focus-ripple-color: transparent !important;
}

.Browser-Header {
  position: relative;
  width: 100%;
  height: 40px;
  z-index: 10;
  padding: 8px;
  border-radius: 5px 0 0;
  background-color: #fff;

  &._mobile-header {
    &::after {
      margin-left: 90px;
    }

    &::before {
      display: none;
    }

    .arrow {
      left: 15px;
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 15px;
    margin: auto;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #e96e4c;
    box-shadow: 18px 0 0 #e6a935, 36px 0 0 #85c33d;
  }

  &::after {
    content: '';
    display: block;
    margin-left: 180px;
    margin-right: 10px;
    height: 100%;
    border-radius: 5px;
    background-color: #eeeeef;
  }

  .arrow {
    position: absolute;
    left: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70px;
    top: 7px;
    line-height: 0;
  }

  .arrow::before, .arrow::after {
    margin: auto;
    padding: 5px 10px;
    border-radius: 4px;
    z-index: 100;
    background: #f1f1f1;
  }

  .arrow::before {
    left: 90px;
    content: url('/app_assets/icons/browser-arrow-icon.svg');

  }

  .arrow::after {
    content: url('/app_assets/icons/browser-arrow-icon.svg');
    transform: rotate(180deg);
    left: 130px;

  }
}

.L-QuickSetup {
  height: 100%;
  position: relative;

  // pf-loading-overlay {
  //   position: absolute;
  //   top: 0;
  //   bottom: 0;
  //   margin: auto;
  //   left: 0;
  //   right: 0;
  // }

  &-Modal {
    .mat-mdc-dialog-container {
      overflow: hidden;
    }
    @include breakpoint-930 {
      height: 670px !important;
      .mat-mdc-dialog-container {
        overflow: auto;
      }
    }
    @include breakpoint-730 {
      height: 100% !important;
    }
  }

  &-Content._configure-view {
    display: grid;
    grid-template-areas: 'header header'
                         'preview content';

    grid-template-columns: 571px 1fr;
    grid-template-rows: auto 1fr;
    gap: 24px 24px;
    @include breakpoint-930 {
      grid-template-columns: 412px 1fr;
    }
    @include breakpoint-730 {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto 1fr;
      grid-template-areas: 'header'
                           'preview'
                           'content';
    }
  }

  &-Header {
    text-align: center;
    ._configure-view & {
      grid-area: header;
    }
  }

  &-Title {
    margin-bottom: 0;
    padding: 0 22px;
    @include breakpoint-730 {
      font-size: 25px;
    }
  }

  &-Description {
    margin-bottom: 0;
    font-size: 18px;
    @include breakpoint-730 {
      font-size: 14px;
    }
  }

  &-Preview {
    ._configure-view & {
      grid-area: preview;
    }
    width: 571px;
    height: 544px;
    overflow: hidden;
    border-radius: 8px;
    @include breakpoint-930 {
      width: 412px;
      height: 393px;
      margin: 0 auto;
    }
    @include breakpoint-450 {
      width: 366px;
      height: 349px;
    }
  }

  &-Controls {
    ._configure-view & {
      grid-area: content;
      @include breakpoint-730 {
        padding-bottom: 12px;
      }
    }
  }

  &-Close {
    position: absolute;
    top: -10px;
    right: -10px;
    cursor: pointer;
    font-size: 26px;
    height: 26px;
    z-index: 10000;
    ion-icon {
      display: flex;
    }
  }

  // &-Content {
  //   display: grid;
  //   grid-template-columns: 640px 1fr;
  //   gap: 24px;
  //   margin-bottom: 12px;
  //   @include breakpoint-930 {
  //     grid-template-columns: 412px 1fr;
  //   }
  //   @include breakpoint-730 {
  //     grid-template-columns: 1fr;
  //   }
  // }

  &-Start {
    display: grid;
    grid-template-columns: 1fr 10px 1fr;
    gap: 12px;
    justify-items: center;
    align-items: center;
    width: 100%;
    text-align: center;

    @include breakpoint-450 {
      grid-template-columns: auto;
      grid-template-rows: 1fr 10px 1fr;
    }

    &-Cell {
      img {
        margin-bottom: 48px;
        width: 100%;
      }
    }

    &-Or {
      position: relative;
      height: 100%;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: flex-end;

      @include breakpoint-450 {
        height: auto;
        width: 100%;
        flex-flow: row;
      }

      &::before,
      &::after {
        content: '';
        height: 50%;
        width: 1px;
        background-color: #e0e0e0;

        @include breakpoint-450 {
          width: 50%;
          height: 1px;
          margin: 10px;
        }
      }
    }
  }


  &-Preview-Wrap {
    pf-coupon-box-preview,
    pf-fortune-wheel-preview,
    pf-newsletter-preview {
      display: block;
      transform-origin: 0 0;
      transform: scale(0.68);
      user-select: none;
      width: 840px;
      height: 840px;
      pointer-events: none;
      @include breakpoint-930 {
        transform: scale(0.49);
      }
      @include breakpoint-540 {
        transform: scale(0.437);
      }
    }
  }

  &-Footer {
    display: flex;
    justify-content: space-between;
  }
}

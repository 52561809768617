$grid-gap: 20px;

.LanguageGrid {
  display: grid;
  grid-template-columns: 180px 1fr 1fr 170px;
  grid-gap: $grid-gap;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  padding: 20px 0;
  color: #4b4b4b;
  @include breakpoint-1400 {
    grid-template-columns: 1fr 1fr 170px;
  }
  @include breakpoint-1200 {
    grid-template-columns: 1fr 1fr;
  }
  @include breakpoint-768 {
    grid-template-columns: 1fr;
  }

  &-Item {
    text-align: center;
    align-self: center;

    &._preview {
      justify-self: stretch;
      align-self: center;
      @include breakpoint-1200 {
        grid-column: span 2;
        justify-self: center;
      }
      @include breakpoint-768 {
        grid-column: span 1;
      }
      @include breakpoint-600 {
        transform: scale(0.7);
      }
    }

    &._label {
      @include breakpoint-1400 {
        text-align: left;
        width: 100%;
        grid-column: span 1;
      }
      @include breakpoint-1200 {
        text-align: center;
        grid-column: span 2;
      }
      @include breakpoint-768 {
        grid-column: span 1;
      }
      
    }

    &._body {
      justify-self: end;
      @include breakpoint-1200 {
        justify-self: center;
      }
    }

    &._changelist {
      justify-self: stretch;
    }
  }
}

.ql-formats {
    margin-right: 10px !important;
    &:last-child {
        margin-right: 0 !important;
    }
}
.ql-undo {
    padding-top: 0 !important;
    &::before {
    content: '↶';
    color: #444444;
    font-size: 20px;
    }
}
.ql-redo {
    padding-top: 0 !important;
    &::before {
    content: '↷';
    color: #444444;
    font-size: 20px;
    }
}
.ql-editor {
    min-height: 50px;
    &:focus {
    box-shadow: 0 0 0.1rem 0.25rem rgba(0, 123, 255, 0.35);
    }
}
.ql-tooltip.ql-editing {
    left: 50% !important;
    transform: translateX(-50%) !important;
}

.SelectWithLabel {
  position: relative;
  label {
    position: absolute;
    top: 4px;
    left: 10px;
    font-size: 12px;
    pointer-events: none;
  }
  &::after {
    content: '▾';
    right: 6px;
    top: 11px;
    position: absolute;
    pointer-events: none;
    color: #333;
  }
  select {
    outline: none !important;
    width: 100%;
    font-weight: 400;
    line-height: 1.5;
    transition: border-color 0.15s ease-in-out, 
                box-shadow 0.15s ease-in-out;
    color: #2a2a2a;
    background-color: #fff;
    border: 2px solid #dadfe6;
    border-radius: 0.375rem;
    font-size: 1rem;
    height: 41px;
    padding: 13px 6px 6px 8px;
    -webkit-appearance: none;
  }
}

$campaign-navigation-color: #7c8c98;

.Mat-SideMenu {
  margin-bottom: 20px;
  display: none;

  @include breakpoint-600 {
    display: block;
  }

    &-Button {
    width: 100%;
    //align-items: center;
    //justify-content: center;
    text-align: center;

    > span.mat-button-wrapper {
      //display: flex !important;
    }

    &:focus {
      outline: none;
    }
  }

  &-Dropdown {
    width: calc(100vw - 80px);
    max-width: initial !important;
  }

  &-Item {
    //display: flex !important;
    //align-items: center;
    &.mat-menu-item {
      text-align: center;
    }

    &:focus {
      outline: none;
    }
  }

  &-Counter {
    display: inline-block;
    //flex: 20px;
    //min-width: 20px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: $campaign-navigation-color;
    color: #fff;
    line-height: 20px;
    text-align: center;
    margin-right: 10px;
  }

  &-Label {
    display: inline-block;
    //flex: 100%;
    min-width: 110px;
    text-align: left;
    color: $campaign-navigation-color;
  }

  &-Item._done &-Counter {
    background: $color-success;
    color: #fff;
  }

  &-Item._done &-Label {
    color: $color-success;
  }

  &-Item._active &-Counter {
    background: $color-primary;
    color: #fff;
  }

  &-Item._active &-Label {
    color: $color-primary;
  }
  .Button {
    margin: 0;
    min-width: 125px;
    @include breakpoint-sm {
      width: auto;
    }
    ion-icon {
      font-size: 22px;
      vertical-align: sub;
      margin-right: 3px;
    }
  }
}


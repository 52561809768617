.pf-form-field {
  .mdc-text-field--focused {
    border-radius: 2px;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
    .mat-mdc-select {
      border: 1px solid #14a5ff !important;
    }
  }
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent;
  }
  .mdc-text-field--disabled.mdc-text-field--filled {
    background-color: transparent;
  }
  .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
    padding: 0;
  }
  &:hover .mat-mdc-form-field-focus-overlay {
    opacity: 0;
  }
  .mat-mdc-form-field-infix {
    width: auto;
  }
  .mat-mdc-form-field-focus-overlay {
    opacity: 0 !important;
  }
  .mdc-text-field {
    padding: 0;
  }
  .mdc-line-ripple {
    display: none;
  }
  .mat-mdc-form-field-infix {
    min-height: auto;
  }
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}
